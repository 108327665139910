import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Device, FORM_TYPE, MetaData} from '../models';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ProjectService} from '../project/project.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {exitFullScreen, openFullScreen, toggleModalById} from '../utils';
import * as moment from 'moment';
import {AuthService} from '../authentication/auth.service';

@Component({
  selector: 'app-device-display',
  templateUrl: './device-display.component.html',
  styleUrls: ['./device-display.component.css']
})
export class DeviceDisplayComponent implements OnInit, OnDestroy {

  form: FormGroup;
  projectId;
  devices: Device[];
  isSubmitted;
  formType;
  currentDeviceId;
  error;
  currentOptionMenu;

  FORM_TYPE;
  metaData: MetaData;

  intervalId;


  constructor(private route: ActivatedRoute, private toaster: ToastrService, private formBuilder: FormBuilder,
              private projectService: ProjectService, private spinner: NgxSpinnerService, protected router: Router) {
    this.FORM_TYPE = FORM_TYPE;
  }

  ngOnInit() {
    openFullScreen();

    this.route.params.subscribe(params => {
      this.projectId = params['id'];
    });

    this.getProject();
    this.getDevices();
    this.onLiveUpdate();
    this.form = this.formBuilder.group(
      {
        name: [null, Validators.compose([Validators.required])],
        description: [null, Validators.compose([])],
        status: [null, Validators.compose([])],
        deviceId: [null, Validators.compose([Validators.required])],
      }
    );

    this.projectService.metaDataSource.subscribe(data => {
      this.metaData = data;
    });
  }


  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  getProject() {
    this.spinner.show();
    this.projectService.getProject(this.projectId)
      .subscribe(response => {
        this.projectService.projectNameSource.next(response.content.name);
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  getDevices(isSilence = false) {
    if (!isSilence) {
      this.spinner.show();
    }

    this.projectService.getDevicesOfProject(this.projectId)
      .subscribe(response => {
        this.devices = response.content;
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  onLiveUpdate() {
    this.intervalId = setInterval(() => {
      this.getDevices(true);
    }, 1000 * 10);
  }

  isDeviceOnline(time, interval) {
    return moment().diff(moment(time, 'YYYY-MM-DD HH:mm')) / (1000 * 60) <= interval;
  }

  getBatteryLevel(level) {
    if (level >= 100) {
      return 'fa-battery-full';
    } else if (level > 75) {
      return 'fa-battery-three-quarters';
    } else if (level > 50) {
      return 'fa-battery-half';
    } else if (level > 25) {
      return 'fa-battery-quarter';
    } else if (level > 0) {
      return 'fa-battery-empty';
    } else {
      return 'fa-bolt';
    }
  }

  getText(level) {
    if (level > 0) {
      return level + '%';
    } else {
      return 'Charging';
    }
  }

  editDevice(device) {
    this.currentDeviceId = device.id;
    this.formType = FORM_TYPE.EDIT;
    this.form.controls.name.setValue(device.name);
    this.form.controls.description.setValue(device.description);
    this.form.controls.status.setValue(device.status);
    this.form.controls.deviceId.setValue('A');
  }

  optionMenu(project) {
    this.currentOptionMenu = project;
  }

  outsideOptionMenu(project) {
    if (this.currentOptionMenu && project.id === this.currentOptionMenu.id) {
      this.currentOptionMenu = null;
    }
  }

  isSuperAdmin() {
    return AuthService.isSuperAdmin();
  }

  goToUserManagement(projectId, deviceId) {
    this.router.navigate(['/device-user', projectId, deviceId]);
  }

}
