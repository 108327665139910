import {Component, OnInit} from '@angular/core';
import {ProjectService} from '../project/project.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {FORM_TYPE, User} from '../models';
import {USER_ROLES} from '../enums';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../authentication/auth.service';
import {debounceTime, tap, switchMap, finalize} from 'rxjs/operators';
import {forEach} from '@angular/router/src/utils/collection';
import {toggleModalById} from '../utils';

@Component({
  selector: 'app-device-user',
  templateUrl: './device-user.component.html',
  styleUrls: ['./device-user.component.css']
})
export class DeviceUserComponent implements OnInit {
  users: User[];
  projectId;
  kitId;
  form: FormGroup;
  error;
  filteredUsers;
  // modes = {};
  currentSelectedUserId = null;
  selectedExistsEmail;
  selectedEmailsId;
  currentSelectedUserRole;
  isSubmitted;
  isLoading;

  formType = FORM_TYPE.ADD;

  FORM_TYPE;

  constructor(private formBuilder: FormBuilder, private projectService: ProjectService, private route: ActivatedRoute,
              private spinner: NgxSpinnerService, private toaster: ToastrService) {
    this.FORM_TYPE = FORM_TYPE;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.kitId = params['deviceId'];
      this.projectId = params['projectId'];
    });

    this.getProject();
    this.getDevice();
    this.getUsers();

    this.form = this.formBuilder.group({
      email: [null, Validators.compose([Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$')])],
      lastName: [null, Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z](?:[a-zA-Z ]||[0-9])*$')])],
      // password: [null, Validators.compose([Validators.required])],
      mobile: [null, Validators.compose([Validators.required])],
      userRoleId: [null, Validators.compose([Validators.required])],
      enable: [null, Validators.compose([])]
      // sms: [null, Validators.compose([])],
      // formType: [null, Validators.compose([])],
    });
    this.form.get('email').valueChanges.pipe(
      debounceTime(300),
      tap(() => this.isLoading = true),
      switchMap(value => this.projectService.filterUser(10000, value !== this.selectedExistsEmail ? value : '')
        .pipe(
          finalize(() => (this.isLoading = false))
        )
      )
    ).subscribe(users => {
      if (this.formType === FORM_TYPE.ADD) {
        this.filteredUsers = users.content;
        if (this.form.get('email').value !== this.selectedExistsEmail) {
          this.form.get('lastName').enable();
          this.form.get('mobile').enable();
          this.form.get('userRoleId').enable();
          this.form.get('userRoleId').setValue('');
          this.form.get('lastName').setValue('');
          this.form.get('mobile').setValue('');
          this.selectedExistsEmail = '';
        }
      }
    });
  }

  getDevice() {
    this.spinner.show();

    this.projectService.getDevice(this.projectId, this.kitId)
      .subscribe(response => {
        // this.device = response.content;
        this.projectService.deviceNameSource.next(response.content.name);

      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  getProject() {
    this.spinner.show();
    this.projectService.getProject(this.projectId)
      .subscribe(response => {
        this.projectService.projectNameSource.next(response.content.name);
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  getUsers() {
    this.spinner.show();
    this.projectService.getUsersOfKit(this.kitId)
      .subscribe(response => {
        this.users = response.content;
        this.users.forEach(user => {
          this.getUserAlert(user);
          // let modes = Object.getOwnPropertyNames(user.alertStatus.modes);
          // modes.forEach(mode => {
          //   if (!this.modes.hasOwnProperty(mode)) {
          //     this.modes[mode] = modes[mode];
          //   }
          // });
        });
      }, error => {
        this.spinner.hide();
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }, () => {
        this.spinner.hide();
      });

    // Object.getOwnPropertyNames(this.modes).forEach(mode => {
    //   const modVal = this.modes[mode];
    //   this.form.addControl(mode, new FormControl(modVal ? modVal : false, [null, Validators.compose([])]));
    // });
  }

  getUserAlert(user: User) {
    this.spinner.show();
    this.projectService.getUserAlert(this.kitId, user.id)
      .subscribe(response => {
        user.alertStatus = response.content;
      }, error => {
        this.spinner.hide();
        if (error.status !== 422) {
          this.toaster.error('Oops... Something went wrong', 'Error!');
        }
      }, () => {
        this.spinner.hide();
      });
  }

  deleteUser() {
    this.spinner.show();
    this.projectService.deleteUserForKit(this.kitId, this.currentSelectedUserId).subscribe(response => {
      toggleModalById('deleteUser');
      this.spinner.hide();
      this.getUsers();
      this.toaster.success('Successfully deleted', 'Success');
    }, error => {
      this.spinner.hide();
      this.toaster.error('Oops... Something went wrong', 'Error!');
    });
  }


  createUser(entity) {
    this.isSubmitted = true;

    if (!this.form.valid && this.currentSelectedUserRole === 10000) {
      return;
    }

    this.spinner.show();
    this.error = null;
    if (this.formType === FORM_TYPE.ADD) {
      let message: string;
      if (this.selectedExistsEmail !== '' && this.selectedExistsEmail === entity.email) {
        entity = {id: this.selectedEmailsId, userRoleId: entity.userRoleId};
        message = 'User has been added successfully';
      } else {
        message = 'A mail has been sent. Please confirm within 24 hours';
      }
      entity.host = window.location.protocol + '//' + window.location.host + '/sign-up';
      entity.type = 'SERVER';
      entity.userRoleId = parseInt(entity.userRoleId, 10);

      this.projectService.createUserForKit(this.kitId, entity).subscribe(response => {
        this.spinner.hide();
        toggleModalById('addNew');
        this.getUsers();
        this.toaster.success(message, 'Success');
      }, error => {
        this.spinner.hide();
        if (error && error.status === 422) {
          this.error = error.error.message;
        } else {
          this.toaster.error('Oops... Something went wrong', 'Error!');
        }
      });
    } else {
      const modes = {};
      this.getUserModesById(this.currentSelectedUserId).forEach(mode => {
        modes[mode] = entity[mode];
      });
      console.log(modes);
      if (this.currentSelectedUserRole !== 10000) {
        this.setUserAlert({
          enable: entity.enable, modes
        });
      } else {
        const requiredValues = {
          email: entity.email,
          lastName: entity.lastName,
          mobile: entity.mobile,
          userRoleId: entity.userRoleId,
          enable: entity.enable
        };
        this.projectService.updateUserForKit(this.kitId, this.currentSelectedUserId, requiredValues).subscribe(response => {
          // this.spinner.hide();
          this.setUserAlert({enable: entity.enable, modes});
          // this.getUsers();
          // this.toaster.success('User updated successfully', 'Success');
        }, error => {
          this.spinner.hide();
          if (error && error.status === 422) {
            this.error = error.error.message;
          } else {
            this.toaster.error('Oops... Something went wrong', 'Error!');
          }
        });
      }

    }
  }

  resendRegistrationMail() {
    const selectedUser = this.users.find(item => {
      return item.id === this.currentSelectedUserId;
    });

    this.spinner.show();
    this.projectService.resendRegistrationMail({
      host: window.location.protocol + '//' + window.location.host + '/sign-up',
      email: selectedUser.email
    }).subscribe(response => {
      this.spinner.hide();
      toggleModalById('resendRegistrationMail');
      this.toaster.success('A mail has been sent again. Please confirm within 24 hours', 'Success');
    }, error => {
      this.spinner.hide();
      if (error && error.status === 422) {
        this.error = error.error.message;
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
    });
  }

  setUserAlert(body) {
    this.projectService.updateAlertStatusForKitUser(this.kitId, this.currentSelectedUserId, body).subscribe(response => {
      this.spinner.hide();
      toggleModalById('addNew');
      this.getUsers();
      this.toaster.success('User updated successfully', 'Success');
    }, error => {
      this.spinner.hide();
      if (error && error.status === 422) {
        this.error = error.error.message;
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
    });
  }


  userEdit(user: User) {
    this.formType = FORM_TYPE.EDIT;
    this.currentSelectedUserId = user.id;
    this.currentSelectedUserRole = user.userRoleId;
    this.error = null;

    if (!user.alertStatus) {
      user.alertStatus = {
        enable: false,
        modes: {SMS: false, EMAIL: false}
      };
    }
    const modes = Object.getOwnPropertyNames(user.alertStatus.modes);
    modes.forEach(mode => {
      if (!this.form.controls.mode) {
        this.form.addControl(mode, new FormControl());
      }
      this.form.controls[mode].setValue(user.alertStatus.modes[mode]);
    });
    this.form.patchValue(modes);

    if (this.currentSelectedUserRole === 10000) {
      this.form.controls.lastName.setValue(user.lastName);
      this.form.controls.email.setValue(user.email);
      this.form.controls.mobile.setValue(user.mobile);

      this.form.get('lastName').enable();
      this.form.get('mobile').enable();
      this.form.get('userRoleId').enable();
      this.form.removeControl('userRoleId');
    }
    // this.form.controls.userRoleId.setValue(user.userRoleId);
    this.form.controls.enable.setValue(user.alertStatus ? user.alertStatus.enable : true);

    // forEach(let mode in user.alertStatus.modes)
    // this.modes = user.alertStatus.modes;
  }

  fillEmailDetails(user) {
    this.form.patchValue(user);
    this.form.get('lastName').disable();
    this.form.get('mobile').disable();
    this.selectedExistsEmail = user['email'];
    this.selectedEmailsId = user['id'];
    this.filteredUsers = null;
  }

  getLevelName(level) {
    return USER_ROLES.find((element) => {
      return element.userRoleId === level;
    }).roleName;
  }

  hasUserCreationAccess() {
    return AuthService.hasUserCreationAccess();
  }

  addUserInit() {
    this.isSubmitted = false;
    this.form.reset();
    this.formType = FORM_TYPE.ADD;
    this.error = null;
    this.currentSelectedUserId = null;

    if (!this.form.contains('userRoleId')) {
      this.form.addControl('userRoleId', new FormControl(Validators.compose([Validators.required])));
    }

    this.form.get('lastName').enable();
    this.form.get('mobile').enable();
    this.form.get('userRoleId').enable();
  }


  isSuperAdmin() {
    return AuthService.isSuperAdmin();
  }

  getUserModesById(id) {
    if (!id) {
      return [];
    }
    return Object.getOwnPropertyNames(this.users.find(e => {
      return e.id === id;
    }).alertStatus.modes);
  }

  getUserById(id): User {
    return this.users.find(e => {
      return e.id === id;
    });
  }

  getModeNames() {
    // return Object.getOwnPropertyNames(this.modes);
  }
}
