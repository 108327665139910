import {Component} from '@angular/core';
import {AuthService} from '../authentication/auth.service';

@Component({
  selector: 'app-home-layout',
  template: `
    <app-header></app-header>
    <app-breadcrumb></app-breadcrumb>
    <app-side-menu *ngIf="hasPermission()"></app-side-menu>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  `,
  styles: []
})
export class HomeLayoutComponent {
  hasPermission() {
    return AuthService.hasCorporateMenuAccess();
  }
}
