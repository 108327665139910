import {Time} from '@angular/common';

export class MagmaResponse<T> {
  statusCode: string;
  statusDescription: string;
  content: T;
}

export class Application {
  id: string;
  name: string;
}

export interface Project {
  id: string;
  name: string;
  application?: Application;
  corporateId: string;
  contactName: string;
  status: string;
  contactPhoneNumber: string;
  contactEmail: string;
  kits?: (string)[] | null;
  alertLevel: number;
  maintainLevel: number;
  userAlertLevel: UserAlertLevel;
  interval: number;
  dataInterval: number;
  offlineCount: number;
  maxNoOfAlerts: number;
  summary?: ProjectSummary;
}

export interface ProjectSummary {
  projectId: string;
  noOfKits: number;
  noOfOfflineKits: number;
}


export interface UserAlertLevel {
  1000: number;
}


// export class Device {
//   id: string;
//   name: string;
//   projectId: string;
//   factor: {};
// }

export class DeviceData {
  nodeId: string;
  data: {};
  time: string;
}

export class MagmaUser {
  client_id: string;
  client_secret: string;
  username: string;
  password: string;
  grant_type: string;
  scope: string;

  constructor(client_id, client_secret, grant_type, scope) {
    this.client_id = client_id;
    this.client_secret = client_secret;
    this.grant_type = grant_type;
    this.scope = scope;
  }
}

export class RefreshUser {
  client_id: string;
  client_secret: string;
  grant_type: string;
  refresh_token: string;

  constructor(client_id, client_secret, grant_type) {
    this.client_id = client_id;
    this.client_secret = client_secret;
    this.grant_type = grant_type;
  }
}

export interface LoginResponse {
  access_token: string;
  refresh_token: string;
  reference: string;
  role: number;
}

// export interface Device {
//   id: string;
//   name: string;
//   deviceId: string;
//   deviceName: string;
//   gpsEnabled: boolean;
//   truckEnabled: boolean;
//   lbsEnabled: boolean;
//   no: number;
//   status: string;
//   factors: Factors;
//   lastUpdateOn: string;
//   alert: string;
// }

export interface Factors {
  B: Reading;
  D: Reading;
  T: Reading;
  H: Reading;
  DS: Reading;
}

export interface Reading {
  low: number;
  displayName: string;
  displayNameFull: string;
  displayValue: string;
  high: number;
  lowOffset: number;
  highOffset: number;
  value: number;
  lowMaintain: number;
  highMaintain: number;
  lowManagement: number;
  highManagement: number;
}

export interface Application {
  id: string;
  name: string;
  password: string;
  dataUrl: string;
}

export interface User {
  name: string;
  username: string;
  lastName: string;
  password: string;
  level: string;
  email: string;
  mobile: string;
  userRoleId: number;
  id: number;
  type: string;
  status: string;
  alertStatus?: AlertStatus;
  accounts?: (string)[] | null;
}

export interface GraphEntity {
  time: string;
  value: number;
}

export class DeviceGraph {
  graphDate: any[];
  graphValue: any[];
  graphData: any[];
  entityName: string;
  propertyDisplayName: string;
}

export interface AlertStatus {
  enable: boolean;
  modes: Modes;
}

export interface Modes {
  SMS: boolean;
  EMAIL: boolean;
}


// export enum LEVEL {
//   ONE = 'Maintenance',
//   '1000' = 'Admin',
// }


export enum FORM_TYPE {
  ADD = 0,
  EDIT = 1,
}

export interface Cooperate {
  id?: string;
  name: string;
  status: string;
  accountId?: string;
  contactName?: string;
  contactPhoneNumber?: string;
  contactEmail?: string;
}

export interface Device {
  id: string;
  name: string;
  status: string;
  model: Model;
  battery: Battery;
  alerts: any;
  alertLevel: number;
  interval: number;
  maintain: boolean;
  persistence: boolean;
  modifiedDate: string;
  lastSeen: string;
  properties?: (PropertiesEntity)[] | null;
  alertStatus?: AlertStatus;
}

export interface Battery {
  low: number;
  high: number;
  reading: number;
}

export interface Model {
  id: string;
  name: string;
  noOfProperties: number;
  properties?: (string)[] | null;
  type: string;
  gpsEnabled: boolean;
  lbsEnabled: boolean;
  batteryEnabled: boolean;
}

// export interface Alert {
//   1: boolean;
// }

export interface PropertiesEntity {
  number: number;
  code: string;
  time: string;
  alert: boolean;
  error: boolean;
  showThreshold: boolean;
  displayValue: string;
  displayName: string;
}

export interface AlertLimitHistory {
  user: string;
  time?: string;
  alertLimit: AlertLimit;
}

export interface AlertLimit {
  id?: string;
  kitId?: string;
  propertyNumber?: number;
  code?: string;
  level: number;
  low: number;
  high: number;
  currentLevelPeriod?;
  nextLevelPeriod?;
  persistence?;
  time?: string;
  status?: string;
}

export interface AlertHistory {
  user: string;
  maintain: boolean;
  alert: boolean;
  time: string;
  level: number;
}

export interface NotificationPolar {
  kitId: string;
  kitName: string;
  projectId: string;
  projectName: string;
  type: string;
  message: string;
  startTime: string;
  endTime: string;
}

export interface MetaData {
  heading: string;
  configuration: Configuration;
}

export interface Configuration {
  imageURL: string;
  aboutUrl: string;
  aboutTitle: string;
  hasFooter: string;
  hasKitUser: string;
}

export interface Geo {
  type: string;
  time: string;
  lat: number;
  lng: number;
}

export interface UserAction {
  // id: string;
  // loginString: string;
  actionAt: string;
  action: string;
}


