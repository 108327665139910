import {Component, OnInit} from '@angular/core';
import {FORM_TYPE, User} from '../models';
import {USER_ROLES} from '../enums';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ProjectService} from '../project/project.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../authentication/auth.service';
import {debounceTime, finalize, switchMap, tap} from 'rxjs/operators';
import {toggleModalById} from '../utils';


@Component({
  selector: 'app-corporate-user',
  templateUrl: './corporate-user.component.html',
  styleUrls: ['./corporate-user.component.css']
})
export class CorporateUserComponent implements OnInit {

  corporateId;
  users: User[];
  form: FormGroup;
  error;
  filteredUsers;
  currentSelectedUserId;
  selectedExistsEmail;
  selectedEmailsId;
  isSubmitted;
  isLoading;

  formType = FORM_TYPE.ADD;

  FORM_TYPE;
  selectedCorporate;

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private projectService: ProjectService,
              private spinner: NgxSpinnerService, private toaster: ToastrService) {
    this.FORM_TYPE = FORM_TYPE;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.corporateId = params['corporateId'];
    });

    // this.getCorporate();
    // this.getUsers();

    this.form = this.formBuilder.group({
      email: [null, Validators.compose([Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$')])],
      lastName: [null, Validators.compose([Validators.pattern('^[a-zA-Z](?:[a-zA-Z]||[0-9])*$'), Validators.required])],
      // password: [null, Validators.compose([Validators.required])],
      mobile: [null, Validators.compose([Validators.required])],
      status: [null, Validators.compose([])],
    });

    this.projectService.corporate.subscribe(data => {
      this.selectedCorporate = data;
      this.getUsers();
    });
    this.form.get('email').valueChanges.pipe(
      debounceTime(300),
      tap(() => this.isLoading = true),
      switchMap(value => this.projectService.filterUser(100, value !== this.selectedExistsEmail ? value : '')
        .pipe(
          finalize(() => (this.isLoading = false))
        )
      )
    ).subscribe(users => {

      if (this.formType === FORM_TYPE.ADD) {
        this.filteredUsers = users.content;
        if (this.form.get('email').value !== this.selectedExistsEmail) {
          this.form.get('lastName').enable();
          this.form.get('mobile').enable();
          this.form.get('lastName').setValue('');
          this.form.get('mobile').setValue('');
          this.selectedExistsEmail = '';
        }
      }
    });
  }

  getCorporate() {
    // this.spinner.show();
    // this.projectService.getProject(this.corporateId)
    //   .subscribe(response => {
    //     this.projectService.projectNameSource.next(response.content.name);
    //   }, error => {
    //     this.spinner.hide();
    //   }, () => {
    //     this.spinner.hide();
    //   });
  }

  getUsers() {
    this.spinner.show();
    this.projectService.getUsersOfCorporate(this.selectedCorporate ? this.selectedCorporate.id : this.corporateId)
      .subscribe(response => {
        this.users = response.content;
      }, error => {
        this.spinner.hide();
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }, () => {
        this.spinner.hide();
      });
  }

  createUser(entity) {
    this.isSubmitted = true;

    if (!this.form.valid) {
      return;
    }

    this.spinner.show();
    this.error = null;
    entity.userRoleId = 100;

    if (this.formType === FORM_TYPE.ADD) {
      let message: string;
      if (this.selectedExistsEmail !== '' && this.selectedExistsEmail === entity.email) {
        entity = {id: this.selectedEmailsId, userRoleId: entity.userRoleId};
        message = 'User has been added successfully';
      } else {
        message = 'A mail has been sent. Please confirm within 24 hours';
      }
      entity.host = window.location.protocol + '//' + window.location.host + '/sign-up';
      entity.type = 'SERVER';

      this.projectService.createUserForCorporate(this.corporateId, entity).subscribe(response => {
        this.spinner.hide();
        toggleModalById('addNew');
        this.getUsers();
        this.toaster.success(message, 'Success');
      }, error => {
        this.spinner.hide();
        if (error && error.status === 422) {
          this.error = error.error.message;
        } else {
          this.toaster.error('Oops... Something went wrong', 'Error!');
        }
      });
    } else {
      this.projectService.updateUserForCorporate(this.corporateId, this.currentSelectedUserId, entity).subscribe(response => {
        this.spinner.hide();
        toggleModalById('addNew');
        this.getUsers();
        this.toaster.success('User updated successfully', 'Success');
      }, error => {
        this.spinner.hide();
        if (error && error.status === 422) {
          this.error = error.error.message;
        } else {
          this.toaster.error('Oops... Something went wrong', 'Error!');
        }
      });
    }


  }

  fillEmailDetails(user) {
    this.form.patchValue(user);
    this.form.get('lastName').disable();
    this.form.get('mobile').disable();
    this.selectedExistsEmail = user['email'];
    this.selectedEmailsId = user['id'];
    this.filteredUsers = null;
  }

  getLevelName(level) {
    return USER_ROLES.find((element) => {
      return element.userRoleId === level;
    }).roleName;
  }

  hasUserCreationAccess() {
    return AuthService.hasUserCreationAccess();
  }

  addUserInit() {
    this.isSubmitted = false;
    this.form.reset();
    this.formType = FORM_TYPE.ADD;
    this.error = null;

    this.form.get('lastName').enable();
    this.form.get('mobile').enable();
  }

  hasUserRoleAccess() {
    return AuthService.getRole() !== 2000;
  }

  deleteUser() {
    this.spinner.show();
    this.projectService.deleteCorporateUser(this.corporateId, this.currentSelectedUserId).subscribe(response => {
      toggleModalById('deleteUser');
      this.spinner.hide();
      this.getUsers();
      this.toaster.success('Successfully deleted', 'Success');
    }, error => {
      this.spinner.hide();
      this.toaster.error('Oops... Something went wrong', 'Error!');
    });
  }

  resendRegistrationMail() {
    const selectedUser = this.users.find(item => {
      return item.id === this.currentSelectedUserId;
    });

    this.spinner.show();
    this.projectService.resendRegistrationMail({
      host: window.location.protocol + '//' + window.location.host + '/sign-up',
      email: selectedUser.email
    }).subscribe(response => {
      this.spinner.hide();
      toggleModalById('resendRegistrationMail');
      this.toaster.success('A mail has been sent again. Please confirm within 24 hours', 'Success');
    }, error => {
      this.spinner.hide();
      if (error && error.status === 422) {
        this.error = error.error.message;
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
    });
  }

  userEdit(user) {
    this.isSubmitted = true;
    this.currentSelectedUserId = user.id;
    this.formType = FORM_TYPE.EDIT;
    this.form.controls.lastName.setValue(user.lastName);
    this.form.controls.email.setValue(user.email);
    this.form.controls.mobile.setValue(user.mobile);
    this.form.controls.status.setValue(user.status);
    this.form.get('lastName').enable();
    this.form.get('mobile').enable();
    this.form.removeControl('userRoleId');
  }

  isSuperAdmin() {
    return AuthService.isSuperAdmin();
  }
}
