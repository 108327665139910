import {Component, OnInit} from '@angular/core';
import * as c3 from 'c3';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute} from '@angular/router';
import {ProjectService} from '../project/project.service';
import * as moment from 'moment';
import {Device} from '../models';
import {toggleClassById} from '../utils';

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.css']
})
export class PdfComponent implements OnInit {

  toDate: any;
  fromDate: any;
  graphData: any[];
  graphDataCopy: any[];
  graphDate: any[];
  graphValue: any[];
  intervalX;
  public now;
  deviceId;
  projectId;
  factor;
  graphType;
  device: Device;
  coporateName:String

  chart;
  isDialog = false;
  isButtonDisable = true;

  constructor(private projectService: ProjectService, private spinner: NgxSpinnerService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    if (window.location.host.toString().match('dialog')) {
      this.isDialog = true;
    }
    this.route.params.subscribe(params => {
      this.fromDate = params['startDate'];
      this.toDate = params['endDate'];
      this.deviceId = params['deviceId'];
      this.projectId = params['projectId'];
      this.factor = params['factor'];
    });
    this.getDevice();
    this.getGraphData(this.fromDate, this.toDate);
    this.now = new Date();
    this.getCoporate()
  }

  getDevice() {
    this.spinner.show();

    this.projectService.getDevice(this.projectId, this.deviceId)
      .subscribe(response => {
        this.device = response.content;
      }, error => {
        this.spinner.hide();
      });
  }

  getCoporate(){
    this.projectService.getProject(this.projectId)
    .subscribe(response => {
      this.coporateName = response.content.name;
    }, error => {
      this.spinner.hide();
    }, () => {
      this.spinner.hide();
    });
  }

  getGraphData(startDate, endDate) {
    this.spinner.show();

    this.projectService.getDeviceHistory(this.projectId, this.deviceId, startDate, endDate, this.factor)
      .subscribe(data => {
        this.graphDataCopy = data.content;
        this.graphDate = [];
        this.graphValue = [];
        this.graphDate.push('x');
        this.graphValue.push('Reading');
        this.graphData = data.content;

        const graphDataProcessed = [];
        if (this.graphData) {
          let lastAcceptedData;
          this.graphData.forEach(value => {
            if (!lastAcceptedData ||
              moment(value.time, 'YYYY-MM-DD HH:mm:ss').diff(moment(lastAcceptedData.time, 'YYYY-MM-DD HH:mm:ss')) / (1000 * 60) > 30) {
              this.graphDate.push(value.time);
              this.graphValue.push(value.value);
              if (this.factor === 'DS') {
                value.value = value.value === 1 ? 'OPEN' : 'CLOSE';
              }
              graphDataProcessed.push(value);
              lastAcceptedData = value;
            }
          });
          this.drawGraph();
        }
        this.graphData = graphDataProcessed;
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  regenerateReport(value: string) {
    const interval = parseInt(value, 10);
    if (interval !== 0) {

      this.graphDate = [];
      this.graphValue = [];
      this.graphDate.push('x');
      this.graphValue.push('Reading');

      const graphDataProcessed = [];
      if (this.graphDataCopy) {
        let lastAcceptedData;
        this.graphDataCopy.forEach((value) => {
          if (!lastAcceptedData ||
            moment(value.time, 'YYYY-MM-DD HH:mm:ss').diff(moment(lastAcceptedData.time, 'YYYY-MM-DD HH:mm:ss')) / (1000 * 60) > interval) {
            this.graphDate.push(value.time);
            this.graphValue.push(value.value);
            if (this.factor === 'DS') {
              value.value = value.value === 1 ? 'OPEN' : 'CLOSE';
            }
            graphDataProcessed.push(value);
            lastAcceptedData = value;
            this.graphData=graphDataProcessed;
          }
        });
        this.drawGraph();
      }
      this.intervalX = interval;
    }
    this.isButtonDisable = true;
  }

  drawGraph() {
    this.chart = c3.generate(
      {
        bindto: '#chart',

        data: {
          x: 'x',
          xFormat: '%Y-%m-%d %H:%M:%S',
          // how the date is parsed
          columns: [this.graphDate, this.graphValue],
          type: this.graphType

        },
        axis: {
          x: {
            label: {
              text: 'Date Time',
              position: 'outer-center',
            },
            type: 'timeseries',
            tick: {
              format: '%Y-%m-%d %H:%M', // how the date is displayed

              culling: {
                max: 10,
              },
              count: 10,
              // rotate: 10
              multiline: true,
            }
          },
          y: {
            label: {
              text: this.device.properties[this.factor].displayName,
              position: 'outer-middle'
            }
          }


        }
      });

  }

  public captureScreen() {
    toggleClassById('intervalBox', 'hide');
    this.chart.resize({width: 900});
    window.print();
    this.chart.resize({width: window.innerWidth * 0.93});
    toggleClassById('intervalBox', 'hide');
  }

}

//
// @media print {
// .pdf-background-image {
//     visble:visible;
//   }
// }

