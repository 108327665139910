import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'changeTimeZone'
})
export class ChangeTimeZonePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const ist = moment.tz(value, 'Asia/Colombo');
    // console.log(ist);
    // console.log(ist.format('YYYY-MM-DD HH:mm:ss'));
    // console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
    // const ut = ist.clone().tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
    // console.log(ut);
    // console.log(ut.format('YYYY-MM-DD HH:mm:ss'));
    return ist.format('YYYY-MM-DD HH:mm:ss');
  }

}
