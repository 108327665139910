import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {ProjectService} from '../../project/project.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {
  form: FormGroup;
  isSubmitted: boolean;
  hash;
  username;
  error;
  isDialog = false;

  constructor(private formBuilder: FormBuilder, private toaster: ToastrService, private projectService: ProjectService,
              private spinner: NgxSpinnerService, private route: ActivatedRoute, protected router: Router) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.hash = params['hash'];
      this.username = params['uname'];
    });

    if (window.location.host.toString().match('dialog')) {
      this.isDialog = true;
    }

    this.form = this.formBuilder.group({
      password: [null, Validators.compose([Validators.required])],
      confirmPassword: [null, Validators.compose([Validators.required])],
    });
  }

  // verifyForgotPassword() {
  //   this.projectService.verifyForgotPassword({forgotPasswordHash: this.hash}).subscribe(response => {
  //     this.spinner.hide();
  //   }, error => {
  //     this.spinner.hide();
  //     if (error.status === 422) {
  //       this.error = error.error.statusDescription;
  //     } else {
  //       this.toaster.error('Oops... Something went wrong', 'Error!');
  //     }
  //   });
  // }

  reset(entity: any) {
    this.isSubmitted = true;

    if (!this.form.valid) {
      return;
    }

    entity.key = this.hash;
    entity.username = this.username;
    this.spinner.show();
    this.projectService.reset(entity).subscribe(response => {
      this.spinner.hide();
      this.toaster.success('Password reset successful', 'Success');
      this.router.navigate(['/login']);
    }, error => {
      this.spinner.hide();
      this.toaster.error('Oops... Something went wrong', 'Error!');
    });
  }
}
