import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as c3 from 'c3';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {formatDate} from '@angular/common';
import {Angular5Csv} from 'angular5-csv/dist/Angular5-csv';
import {ProjectService} from '../project/project.service';
import {AlertLimit, Device, GraphEntity} from '../models';
import {ToastrService} from 'ngx-toastr';
import * as _ from 'underscore';
import {changeTimeZone} from '../utils';

@Component({
  selector: 'app-device-graph',
  templateUrl: './device-graph.component.html',
  styleUrls: ['./device-graph.component.css']
})
export class DeviceGraphComponent implements OnInit, AfterViewInit {
  function: any;
  toDate: any;
  fromDate: any;
  fromString: string;
  toString: string;
  graphDate: any[];
  graphValue: any[];
  graphData: GraphEntity[];

  deviceId;
  projectId;
  isBattery: boolean;

  device: Device;
  currentSelectedTab = 0;

  error;

  noOfgraphXpoints = 8;
  graphRangeType;
  currentgraphRangeType = 0;
  BATTERY_NUMBER = -10;
  alerts: AlertLimit[];
  graphMax;
  graphMin;

  constructor(private route: ActivatedRoute, private router: Router, private toaster: ToastrService,
              private spinner: NgxSpinnerService, private projectService: ProjectService) {
  }

  ngOnInit() {
    const temp = ((window.innerWidth * 0.55) / 80);
    this.noOfgraphXpoints = temp - temp % 1;
    this.route.params.subscribe(params => {
      this.deviceId = params['deviceId'];
      this.projectId = params['projectId'];
      this.isBattery = params['isBattery'] === 'true';
    });

    this.getProject();
    const lastGraphRangeType = parseInt(localStorage.getItem('graphRangeType' + this.deviceId), 10);
    this.graphRangeType = lastGraphRangeType ? lastGraphRangeType : 0;
    this.getDevice();

    const today = new Date();
    this.toDate = today;
    this.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

  }

  getProject() {
    this.spinner.show();
    this.projectService.getProject(this.projectId)
      .subscribe(response => {
        this.projectService.projectNameSource.next(response.content.name);
      }, error => {
        this.spinner.hide();
      });
  }

  getDevice() {
    this.spinner.show();

    this.projectService.getDevice(this.projectId, this.deviceId)
      .subscribe(response => {
        this.device = response.content;
        this.projectService.deviceNameSource.next(this.device.name);

        this.getAlertLimitForProperty();

        this.loadGraph();
      }, error => {
        this.spinner.hide();
      });
  }

  loadGraph() {
    this.currentgraphRangeType = this.graphRangeType;
    localStorage.setItem('graphRangeType' + this.deviceId, '' + this.graphRangeType);
    const today = new Date();

    if (this.graphRangeType === 2) {
      this.toDate = today;
      this.fromDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    } else if (this.graphRangeType === 1) {
      this.toDate = today;
      this.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    } else if (this.graphRangeType === 0) {
      this.toDate = today;
      this.fromDate = today;
    }

    this.toString = formatDate(this.toDate, 'yyyy-MM-dd', 'lk');
    this.fromString = formatDate(this.fromDate, 'yyyy-MM-dd', 'lk');

    this.getGraphData(this.fromString, this.toString);
  }

  getGraphData(startDate, endDate) {
    this.spinner.show();
    this.error = null;
    this.graphDate = [];
    this.graphValue = [];
    this.projectService.getDeviceHistory(this.projectId, this.deviceId, startDate, endDate,
      this.isBattery ? this.BATTERY_NUMBER : this.device.properties[this.currentSelectedTab].number)
      .subscribe(data => {
        if (data.statusCode === 'E1003') {
          this.error = 'No Data Available';
        } else {
          this.graphDate.push('x');
          this.graphValue.push('Reading');
          this.graphData = data.content;
          // console.log(this.graphData);
          if (this.graphData.length > 0) {
            this.graphData.forEach(value => {
              this.graphDate.push(value.time);
              this.graphValue.push(value.value);

            });
            this.drawGraph();
            this.error = null;
          } else {
            this.error = 'No Data Available';
          }
        }
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  adjustTimeZone(data) {
    return _.map(data, item => {
      item.time = changeTimeZone(item.time);
      return item;
    });
  }

  exportFunction() {
    // download the file using old school javascript method
    // this.exportAsService.save(this.exportAsConfig, 'My File Name');
    // get the data as base64 or json object for json type - this will be helpful in ionic or SSR

    console.log(this.graphData);

    const modifiedSet = _.map(this.graphData, e => {
      return _.pick(e, ['time', 'value']);
    });
    modifiedSet.unshift(['Time', this.device.properties[this.currentSelectedTab].displayName]);
    const csv = new Angular5Csv(modifiedSet,
      this.device.name + '-' + this.device.properties[this.currentSelectedTab].displayName + '-Report');
  }

  findGraphMinMax(arr) {
    let min = 100000;
    let max = 0;

    for (let i = 1; i < arr.length; i++) {
      if (arr[i] < min) {
        min = arr[i];
      }
      if (arr[i] > max) {
        max = arr[i];
      }
    }
    this.graphMax = max;
    this.graphMin = min;

    this.getThresholdLevel().map(val => {
      if (val.value > this.graphMax) {
        this.graphMax = val.value;
      } else if (val.value < this.graphMin) {
        this.graphMin = val.value - 1;
      }
    });

  }

  drawGraph() {

    this.findGraphMinMax(this.graphValue);
    // @ts-ignore
    // @ts-ignore
    c3.generate(
      {
        bindto: '#chart',
        data: {
          x: 'x',
          xFormat: '%Y-%m-%d %H:%M:%S',
          columns: [this.graphDate, this.graphValue]
        },
        grid: {
          y: {
            lines: this.getThresholdLevel()
          }
        },
        subchart: {
          show: true
        },
        axis: {
          x: {
            show: true,
            label: {
              text: 'Date Time',
              position: 'outer-center',
            },

            type: 'timeseries',
            tick: {
              format: '%Y-%m-%d %H:%M', // how the date is displayed
              fit: true,
              culling: {
                max: 10,
              },
              count: this.noOfgraphXpoints,
              // rotate: 10
              multiline: true,
            }
          },
          y: {
            label: {
              text: this.isBattery ? 'Battery' : this.device.properties[this.currentSelectedTab].displayName,
              position: 'outer-middle'
            },
            max: this.graphMax,
            min: this.graphMin
          },

        }
      });
  }

  ngAfterViewInit() {
    if (document.getElementsByTagName('svg').item(0)) {
      document.getElementsByTagName('svg').item(0).removeAttribute('style');
    }
  }

  reportDownload() {
    if (this.graphData && this.graphData.length > 0) {
      window.open('/pdf/' + this.projectId + '/' + this.deviceId + '/' + formatDate(this.fromDate, 'yyyy-MM-dd', 'lk') +
        '/' + formatDate(this.toDate, 'yyyy-MM-dd', 'lk') + '/' + this.device.properties[this.currentSelectedTab].number, '_blank');

    } else {
      this.toaster.warning('No data available to generate report', 'Warning!');
    }
  }

  selectTab(index) {
    this.currentSelectedTab = index;
    this.getAlertLimitForProperty();
    this.loadGraph();
  }

  getThresholdLevel() {
    if (this.isBattery) {
      return [];
    }

    // // multi level alerts
    // const linesMax = this.alerts.map(e => {
    //   return {value: e.high, text: 'Max Threshold Level [' + e.level + ']', position: 'middle'};
    // });
    //
    // const linesMin = this.alerts.map(e => {
    //   return {value: e.low, text: 'Min Threshold Level [' + e.level + ']', position: 'middle'};
    // });
    //
    // return [...linesMax, ...linesMin];

    return [
      {value: this.alerts ? this.alerts[0].high : 0, text: 'Max Threshold Level', position: 'middle'},
      {value: this.alerts ? this.alerts[0].low : 0, text: 'Min Threshold Level', position: 'middle'},
    ];
  }

  getAlertLimitForProperty() {
    this.spinner.show();

    this.projectService.getAlertLimitForProperty(this.deviceId, this.device.properties[this.currentSelectedTab].number)
      .subscribe(response => {
        this.alerts = response.content;
        if (this.alerts.length !== this.device.alertLevel) {
          const diff = this.device.alertLevel - this.alerts.length;
          const length = this.alerts.length;
          for (let i = 0; i < diff; i++) {
            this.alerts.push({
              level: length + i + 1,
              low: 0,
              high: 0
            });
          }
        }
      }, error => {
        this.spinner.hide();
      }, () => {
        // this.spinner.hide();
      });
  }
}



