import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {ProjectService} from '../../project/project.service';
import {Router} from '@angular/router';


@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  form: FormGroup;
  error: null;
  isSubmitted: boolean;
  isDialog = false;

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private toaster: ToastrService,
              private projectService: ProjectService, protected router: Router) {
  }

  ngOnInit() {
    if (window.location.host.toString().match('dialog')) {
      this.isDialog = true;
    }
    this.form = this.formBuilder.group({
      email: [null, Validators.compose([Validators.required, Validators.email])],
    });
  }

  resetPassword(entity: any) {
    this.isSubmitted = true;

    if (!this.form.valid) {
      return;
    }

    entity.host = window.location.protocol + '//' + window.location.host + '/reset';

    this.spinner.show();
    this.error = null;
    this.projectService.forgotPassword(entity).subscribe(response => {
      this.spinner.hide();
      this.toaster.success('Check mail to reset password', 'Success');
      this.router.navigate(['/login']);
    }, error => {
      this.spinner.hide();
      console.log(error);
      if (error.status === 422) {
        this.error = error.error.message;
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
    });
  }

  emailChanges() {
    this.error = null;
  }
}
