import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {catchError, tap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  isRefreshTokenRequest = false;

  constructor(private router: Router, private toast: ToastrService, private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // req = req.clone({ headers: req.headers.set('TimeZone', new Date().getTimezoneOffset().toString()) });

    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          console.log(event.body);
        }
      }),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            // if (this.isRefreshTokenRequest) {
            //   this.router.navigate(['login']);
            //   throw null;
            // } else {
            //   this.getRefreshTokenAndProcess(req, next);
            // }
            this.toast.warning('Session expired. Please login again', 'Warning!!');
            this.router.navigate(['login']);

            // this.getRefreshTokenAndProcess(req, next);

          } else if (error.status === 500) {
            this.toast.error('Oops! Something went wrong.', 'Error!!');
            throw null;
          }
        }
        throw error;
      }));


  }

  getRefreshTokenAndProcess(req, next) {
    this.authService.refreshToken()
      .subscribe(data => {
        AuthService.loadCredential(data);
        const authReqRepeat = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'))
        });

        // resend the request
        next.handle(authReqRepeat);
      }, error => {
        this.router.navigate(['login']);
      }, () => {
      });

  }
}


