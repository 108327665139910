import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectService} from '../project/project.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Device, FORM_TYPE, MetaData} from '../models';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
// import * as moment from 'moment';
import {AuthService} from '../authentication/auth.service';
import {exitFullScreen, toggleModalById} from '../utils';
import * as moment from 'moment-timezone';

import * as XLSX from 'xlsx';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.css']
})
export class DevicesComponent implements OnInit, OnDestroy {
  form: FormGroup;
  projectId;
  devices: Device[];
  isSubmitted;
  formType;
  currentDeviceId;
  error;
  currentOptionMenu;

  FORM_TYPE;
  metaData: MetaData;

  intervalId;
  toggleTrue: boolean;

  data: JSON;
  noFileSelected = true;
  finalArray: Array<any> = [];
  files: File[] = [];
  currentName;


  constructor(private route: ActivatedRoute, private toaster: ToastrService, private formBuilder: FormBuilder,
              private projectService: ProjectService, private spinner: NgxSpinnerService, protected router: Router) {
    this.FORM_TYPE = FORM_TYPE;
  }

  ngOnInit() {
    exitFullScreen();
    this.route.params.subscribe(params => {
      this.projectId = params['id'];
    });

    if (this.projectId === 'D_U') {
      this.getDevicesForDU();
      this.onLiveUpdateForDU();
    } else {
      this.getProject();
      this.getDevices();
      this.onLiveUpdate();
    }

    this.form = this.formBuilder.group(
      {
        name: [null, Validators.compose([Validators.required])],
        description: [null, Validators.compose([])],
        status: [null, Validators.compose([])],
        deviceId: [null, Validators.compose([Validators.required])],
        area: [null, Validators.compose([])],
        item: [null, Validators.compose([])]
      }
    );

    this.projectService.metaDataSource.subscribe(data => {
      this.metaData = data;
    });
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  getProject() {
    this.spinner.show();
    this.projectService.getProject(this.projectId)
      .subscribe(response => {
        this.projectService.projectNameSource.next(response.content.name);
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  getDevices(isSilence = false) {
    if (!isSilence) {
      this.spinner.show();
    }

    this.projectService.getDevicesOfProject(this.projectId)
      .subscribe(response => {
        this.devices = response.content;
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }


  getDevicesForDU(isSilence = false) {
    if (!isSilence) {
      this.spinner.show();
    }

    this.projectService.getDevice(this.projectId, AuthService.getReference())
      .subscribe(response => {
        this.devices = [response.content];
        // this.projectService.deviceNameSource.next(this.device.name);

        // this.getAlertLimitForProperty();

        // this.loadGraph();
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  onLiveUpdate() {
    this.intervalId = setInterval(() => {
      this.getDevices(true);
    }, 1000 * 60);
  }

  onLiveUpdateForDU() {
    this.intervalId = setInterval(() => {
      this.getDevicesForDU(true);
    }, 1000 * 60);
  }

  createDevice(entity) {
    this.isSubmitted = true;
    this.error = null;
    if (!this.form.valid) {
      return;
    }
    entity.metaData = {area: entity.area, item: entity.item};
    this.spinner.show();
    entity.id = entity.deviceId;
    if (this.formType === FORM_TYPE.ADD) {
      this.projectService.createDevice(this.projectId, entity).subscribe(response => {
        this.spinner.hide();
        toggleModalById('addNew');
        this.getDevices();
        // this.devices.push(entity);
        this.toaster.success('Device Created successfully', 'Success');
      }, error => {
        this.spinner.hide();
        if (error.status === 422) {
          this.error = error.error.message;
        } else {
          this.toaster.error('Oops... Something went wrong', 'Error!');
        }
      });
    } else {
      this.projectService.updateDevice(this.projectId, this.currentDeviceId, entity).subscribe(response => {
        this.spinner.hide();
        toggleModalById('addNew');
        this.getDevices();
        this.toaster.success('Device updated successfully', 'Success');
        this.getDevices();
      }, error => {
        this.spinner.hide();
        if (error.status === 422) {
          this.error = error.error.message;
        } else {
          this.toaster.error('Oops... Something went wrong', 'Error!');
        }
      });
    }
  }

  isDeviceOnline(time, interval) {
    if (!time) {
      return false;
    }
    const ist = moment.tz(time, 'Asia/Colombo');
    const ut = ist.clone().tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
    return moment().diff(ut) / (1000 * 60) <= interval;
  }

  getBatteryLevel(level) {
    if (level >= 100) {
      return 'fa-battery-full';
    } else if (level > 75) {
      return 'fa-battery-three-quarters';
    } else if (level > 50) {
      return 'fa-battery-half';
    } else if (level > 25) {
      return 'fa-battery-quarter';
    } else if (level > 0) {
      return 'fa-battery-empty';
    } else {
      return 'fa-bolt';
    }
  }

  getText(level) {
    if (level > 0) {
      return level + '%';
    } else {
      return 'Charging';
    }
  }

  addDeviceInit() {
    this.isSubmitted = false;
    this.formType = FORM_TYPE.ADD;
    this.form.reset();
    this.error = null;
  }

  editDevice(device) {
    this.currentDeviceId = device.id;
    this.formType = FORM_TYPE.EDIT;
    this.form.controls.name.setValue(device.name);
    this.form.controls.description.setValue(device.description);
    this.form.controls.status.setValue(device.status);
    this.form.controls.deviceId.setValue('A');
    this.form.controls.area.setValue(device.metaData ? device.metaData.area : '');
    this.form.controls.item.setValue(device.metaData ? device.metaData.item : '');
  }

  optionMenu(project) {
    this.currentOptionMenu = project;
  }

  outsideOptionMenu(project) {
    if (this.currentOptionMenu && project.id === this.currentOptionMenu.id) {
      this.currentOptionMenu = null;
    }
  }

  deleteProject() {
    this.spinner.show();
    this.projectService.deleteDevice(this.projectId, this.currentDeviceId).subscribe(response => {
      toggleModalById('deleteUser');
      this.spinner.hide();
      this.getDevices();
      this.toaster.success('Successfully deleted', 'Success');
    }, error => {
      this.spinner.hide();
      this.toaster.error('Oops... Something went wrong', 'Error!');
    });
  }

  isSuperAdmin() {
    return AuthService.isSuperAdmin();
  }

  goToUserManagement(projectId, deviceId) {
    this.router.navigate(['/device-user', projectId, deviceId]);
  }

  changeButton() {
    if (this.toggleTrue) {
      this.toggleTrue = false;
    } else {
      this.toggleTrue = true;
    }

    console.log('Initial Values ');
  }

  toggleUpload() {
    toggleModalById('upload data');
  }

  idFinder(device) {
    this.currentDeviceId = device.id;
    this.currentName = device.name;
  }

  onFileChange(event) {
    if (this.files && this.files.length >= 1) {
      this.onRemove(this.files[0]);
      this.noFileSelected = false;
    }
    this.files.push(...event.addedFiles);
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();

    if (this.files.length == 1) {
      const file = this.files[0];
      reader.onload = event => {
        this.finalArray = [];
        const data = reader.result;
        workBook = XLSX.read(data, {type: 'binary'});
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet, {range: 11, header: ['Date', 'Time', 'Temperature'], dateNF: 'dd"."mm"."yyyy'});
          return initial;
        }, {});

        const dataString = JSON.stringify(jsonData.Sheet1);
        this.data = jsonData.Sheet1;

        this.noFileSelected = false;

        for (let i = 0; i < Object.keys(this.data).length - 1; i++) {

          const date_arr = this.data[i].Date.split('/', 3);

          const formatted_date = date_arr[2] + '-' + date_arr[1] + '-' + date_arr[0].substring(1);

          const datetime = formatted_date + ' ' + this.data[i].Time;

          this.finalArray.push(
            {
              'alert': true,
              'code': 'T',
              'error': true,
              'kitId': this.currentDeviceId,
              'number': 0,
              'value': this.data[i].Temperature,
              'time': datetime
            }
          );
        }

      };
      reader.readAsBinaryString(file);

    }
    if (this.files.length == 0) {
      this.noFileSelected = true;
    }

  };

  send_data() {
    this.spinner.show();
    this.toggle();
    this.projectService.createProperties(this.currentDeviceId, this.finalArray).subscribe(response => {
      this.spinner.hide();
      this.toaster.success('Uploaded Successfully', 'Success');
      this.noFileSelected = true;
      this.reset();
      this.toggleUpload();
      this.getDevices();

    }, error => {
      this.spinner.hide();

      if (error && error.status === 422) {
        error = error.error.message;
        this.toaster.error(error);

      } else {
        this.toaster.error('Oops.. Something went wrong ');

      }
    });
  }

  reset() {
    this.files = [];
    this.finalArray = [];
    this.noFileSelected = true;
  }

  toggle() {
    toggleModalById('upload');
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }
}
