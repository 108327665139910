export const USER_ROLES = [
  {userRoleId: 100, roleName: 'Corporate Admin'},
  {userRoleId: 1000, roleName: 'Project Admin'},
  {userRoleId: 2000, roleName: 'Maintenance L1'},
  {userRoleId: 3000, roleName: 'Maintenance L2'},
  {userRoleId: 4000, roleName: 'Maintenance L3'},
  {userRoleId: 5000, roleName: 'Maintenance L4'},
  {userRoleId: 6000, roleName: 'Maintenance L5'},
  {userRoleId: 10000, roleName: 'Kit User'}
];
