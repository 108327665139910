import {Component, HostListener, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ProjectService} from '../project/project.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {NotificationPolar} from '../models';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  notifications: NotificationPolar[] = [];
  error;
  page = 0;
  isAllRetrieved = false;
  isFetchingData = false;

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private projectService: ProjectService,
              private spinner: NgxSpinnerService, private toaster: ToastrService) {
  }

  ngOnInit() {
    this.spinner.show();
    this.getNotification(0);
  }

  getNotification(offset: number, concat?) {
    this.isFetchingData = true;
    this.projectService.getNotificationForUser(offset)
      .subscribe(response => {
        if (response.content.length < 10) {
          this.isAllRetrieved = true;
        }
        this.notifications = this.notifications.concat(response.content);
      }, error => {
        this.spinner.hide();
        this.isFetchingData = false;
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }, () => {
        this.spinner.hide();
        this.isFetchingData = false;
      });
  }

  loadMoreAtEnd(e): void {
    if (this.bottomReached() && !this.isAllRetrieved) {
      this.getNotification(++this.page);
    }
  }

  bottomReached(): boolean {
    const offset = document.getElementById('main3').offsetHeight;
    const scrolledHeight = document.getElementById('main3').scrollTop;
    return (offset + scrolledHeight + 1) >= document.getElementById('main3').scrollHeight;
  }
}


