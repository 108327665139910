import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {ProjectService} from '../../project/project.service';
import {ActivatedRoute, Router} from '@angular/router';
import {isPasswordMatches} from '../../validator';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  form: FormGroup;
  isSubmitted: boolean;
  hash;
  email;
  error;
  isDialog = false;

  accountValidationMessages = {
    username: [
      {type: 'required', message: 'Username is required'},
      {type: 'minlength', message: 'Username must be 5 characters long'},
      {type: 'maxlength', message: 'Username cannot be more than 25 characters long'},
      {type: 'pattern', message: 'Username must contain only numbers and letters'},
      {type: 'validUsername', message: 'Username has already been taken'}
    ],
    password: [
      {type: 'required', message: 'Password is required'},
      {type: 'minlength', message: 'Password must be at least 5 characters long'},
    ],
  };


  constructor(private formBuilder: FormBuilder, private toaster: ToastrService, private projectService: ProjectService,
              private route: ActivatedRoute, private spinner: NgxSpinnerService, protected router: Router) {
  }

  ngOnInit() {
    if (window.location.host.toString().match('dialog')) {
      this.isDialog = true;
    }

    this.route.queryParams.subscribe(params => {
      this.hash = params.hash;
      this.email = params.email;
    });

    this.form = this.formBuilder.group({
      username: [null, Validators.compose([Validators.maxLength(25),
        Validators.minLength(5),
        Validators.pattern('^[a-zA-Z](?:[a-zA-Z]||[0-9])*$'),
        Validators.required])],
      password: [null, Validators.compose([Validators.minLength(5),
        Validators.required])],
      confirmPassword: [null, Validators.compose([Validators.required])],
    }, {validator: isPasswordMatches('password', 'confirmPassword')});
  }

  register(entity: any) {
    this.isSubmitted = true;
    this.error = null;
    if (!this.form.valid) {
      return;
    }

    entity.key = this.hash;
    entity.email = this.email;
    this.spinner.show();
    this.projectService.register(entity).subscribe(response => {
      this.spinner.hide();
      this.toaster.success('Account Created successfully', 'Success');
      this.router.navigate(['/login']);
    }, error => {
      this.spinner.hide();
      if (error.status === 422) {
        this.error = error.error.message;
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
    });
  }
}
