import {Component, OnInit} from '@angular/core';
import {AuthService} from '../auth.service';
import {LoginResponse} from '../../models';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {ProjectService} from '../../project/project.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginResponse: LoginResponse;
  loginError: string;
  form: FormGroup;
  isSubmitted;
  public showPassword: boolean;
  isDialog = false;

  constructor(private authService: AuthService, protected router: Router, private formBuilder: FormBuilder,
              private spinner: NgxSpinnerService, private projectService: ProjectService) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      username: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
    });

    if (window.location.host.toString().match('dialog')) {
      this.isDialog = true;
    }
  }

  signIn(auth) {
    this.isSubmitted = true;

    if (this.form.valid) {
      this.loginWithUserName(auth);
    }
  }

  loginWithUserName(auth) {
    auth.grant_type = 'password';
    auth.client_id = 'polar-client';
    auth.client_secret = 'polar-secret';
    console.log(auth);
    this.spinner.show();
    this.authService.loginWithUserName(auth).subscribe(
      (response) => {
        this.spinner.hide();
        this.loginResponse = response;
        AuthService.loadCredential(this.loginResponse);
        this.projectService.corporateSource.next(null);
        if (response.role < 10000) {
          const reqUrl = localStorage.getItem('REQUESTED_URL');
          this.router.navigate([reqUrl ? reqUrl : '']);
          localStorage.setItem('REQUESTED_URL', '');
        } else if (response.role === 10000) {
          this.router.navigate(['/device', 'D_U']);
        }
      },
      (error) => {
        this.spinner.hide();
        this.loginError = error;
      });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }
}
