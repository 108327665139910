import * as moment from 'moment-timezone';

export function changeFavicon(src) {
  const link = document.createElement('link');
  const oldLink = document.getElementById('dynamic-favicon');
  link.id = 'dynamic-favicon';
  link.rel = 'shortcut icon';
  link.href = src;
  if (oldLink) {
    document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
}

export function toggleModalById(id) {
  document.getElementById(id).classList.toggle('hide');
}

export function toggleClassById(id, cssC) {
  document.getElementById(id).classList.toggle(cssC);
}

export function toggleProfileMenu() {
  document.getElementById('sDropdown2').classList.toggle('show');
  document.getElementById('dropUserBtn').classList.toggle('dropbtn-focus');
}

export function hideProfileMenu() {
  document.getElementById('sDropdown2').classList.remove('show');
  document.getElementById('dropUserBtn').classList.remove('dropbtn-focus');
}

export function openNavSet() {
  const sideMenu = document.getElementById('mySidenav');
  const breadCrumb = document.getElementById('main');
  const mainBody = document.getElementById('main2');
  if (sideMenu && sideMenu.classList.contains('side-menu-width')) {
    if (sideMenu.classList.contains('side-menu-padding')) {

    } else {
      mainBody.classList.add('side-menu-padding');
    }
  } else {
    if (sideMenu && sideMenu.classList.contains('side-menu-padding')) {
      mainBody.classList.remove('side-menu-padding');
    } else {

    }
  }
}

interface FsDocument extends HTMLDocument {
  mozFullScreenElement?: Element;
  msFullscreenElement?: Element;
  fullscreenElement?: Element;
  webkitFullscreenElement?: Element;
  msExitFullscreen?: () => void;
  mozCancelFullScreen?: () => void;
  webkitExitFullscreen?: () => void;
}


export function isFullScreen(): boolean {
  const fsDoc = document as FsDocument;

  return !!(fsDoc.fullscreenElement || fsDoc.mozFullScreenElement || fsDoc.webkitFullscreenElement || fsDoc.msFullscreenElement);
}

interface FsDocumentElement extends HTMLElement {
  msRequestFullscreen?: () => void;
  mozRequestFullScreen?: () => void;
  webkitRequestFullscreen?: () => void;
}

export function openFullScreen() {
  const fsDocElem = document.documentElement as FsDocumentElement;

  if (fsDocElem.requestFullscreen) {
    fsDocElem.requestFullscreen();
  } else if (fsDocElem.msRequestFullscreen) {
    fsDocElem.msRequestFullscreen();
  } else if (fsDocElem.mozRequestFullScreen) {
    fsDocElem.mozRequestFullScreen();
  } else if (fsDocElem.webkitRequestFullscreen) {
    fsDocElem.webkitRequestFullscreen();
  }
}

export function exitFullScreen() {
  const fsDoc = document as FsDocument;

  if (fsDoc.fullscreen) {
    fsDoc.exitFullscreen();
  } else if (fsDoc.msExitFullscreen) {
    fsDoc.msExitFullscreen();
  } else if (fsDoc.mozCancelFullScreen) {
    fsDoc.mozCancelFullScreen();
  } else if (fsDoc.webkitExitFullscreen) {
    fsDoc.webkitExitFullscreen();
  }
}

export function refreshViewAnimation(time) {
  document.getElementById('dwar' + 0).classList.remove('device-off-animation');
  setTimeout(() => {
    document.getElementById('dwar' + 0).classList.add('device-off-animation');
  }, time);
}

export function shiftDevicesOnOverflow(time) {
  document.getElementById('dnor' + 0).classList.remove('device-off-animation');
  setTimeout(() => {
    document.getElementById('dnor' + 0).classList.add('device-off-animation');
  }, time);
}

export function changeTimeZone(time) {
  const ist = moment.tz(time, 'Asia/Colombo');
  return ist.format('YYYY-MM-DD HH:mm:ss');
}

