import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import * as c3 from 'c3';
import {ProjectService} from '../project/project.service';
import {NgxSpinnerService} from 'ngx-spinner';
import * as moment from 'moment';
import {DeviceGraph} from '../models';
import {formatDate} from '@angular/common';
import {toggleClassById} from '../utils';

@Component({
  selector: 'app-pdf-all',
  templateUrl: './pdf-all.component.html',
  styleUrls: ['./pdf-all.component.css']
})
export class PdfAllComponent implements OnInit {
  deviceIds;
  factors;
  type;
  projectId;

  selectedDeviceIds;
  selectedFactors;
  selectedReportTypes;


  toDate: any;
  fromDate: any;
  c3Graphs: any[] = [];
  graphDataCopy: any[] = [];
  deviceGraphDataArray: DeviceGraph[] = [];
  intervalX;

  public now;
  isDialog = false;
  k = 0;
  isButtonDisable = false;

  constructor(private route: ActivatedRoute, private projectService: ProjectService, private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    if (window.location.host.toString().match('dialog')) {
      this.isDialog = true;
    }
    this.route.params.subscribe(params => {
      this.deviceIds = params['deviceIds'];
      this.projectId = params['projectId'];
      this.fromDate = params['startDate'];
      this.toDate = params['endDate'];
      this.factors = params['factors'];
      this.type = params['type'];
    });

    this.selectedDeviceIds = this.deviceIds.split(',');
    this.selectedFactors = this.factors.split(',');
    this.selectedReportTypes = this.type.split(',');

    this.now = new Date();

    this.selectedDeviceIds.forEach(item => {
      this.getDevice(item);
    });
  }

  getDevice(deviceId, interval = 30) {
    this.spinner.show();

    this.projectService.getDevice(this.projectId, deviceId)
      .subscribe(response => {
        this.selectedFactors.forEach(e => {
          this.getGraphData(deviceId, formatDate(this.fromDate, 'yyyy-MM-dd', 'lk'),
            formatDate(this.toDate, 'yyyy-MM-dd', 'lk'), e, this.k++, response.content, interval);
        });
      }, error => {
        this.spinner.hide();
      });
  }

  getGraphData(deviceId, startDate, endDate, factor, index, device, interval) {
    this.spinner.show();

    this.projectService.getDeviceHistory(this.projectId, deviceId, startDate, endDate, factor)
      .subscribe(data => {
        this.graphDataCopy.push({data, device, index, factor});
        const deviceGraph: DeviceGraph = new DeviceGraph();

        deviceGraph.graphDate = [];
        deviceGraph.graphValue = [];
        deviceGraph.graphDate.push('x');
        deviceGraph.graphValue.push('Reading');
        deviceGraph.graphData = data.content;
        deviceGraph.entityName = device.name;
        deviceGraph.propertyDisplayName = device.properties[factor].displayName;

        const graphDataProcessed = [];
        if (deviceGraph.graphData) {
          let lastAcceptedData;
          deviceGraph.graphData.forEach(value => {
            if (!lastAcceptedData ||
              moment(value.time, 'YYYY-MM-DD HH:mm').diff(moment(lastAcceptedData.time, 'YYYY-MM-DD HH:mm')) / (1000 * 60) > interval) {
              deviceGraph.graphDate.push(value.time);
              deviceGraph.graphValue.push(value.value);
              graphDataProcessed.push(value);
              lastAcceptedData = value;
            }
          });
        }
        deviceGraph.graphData = graphDataProcessed;

        if (data.content && data.content.length > 0) {
          this.deviceGraphDataArray.push(deviceGraph);

          setTimeout(() => this.drawGraph(this.deviceGraphDataArray[index].graphDate, this.deviceGraphDataArray[index].graphValue,
            '#chart' + index, index, device.properties[factor].displayName), 5000);
        }


      }, error => {
        this.spinner.hide();
      });
  }

  drawGraph(xData, yData, chartId, index, displayName) {
    const chart = c3.generate(
      {
        bindto: chartId,
        data: {
          x: 'x',
          xFormat: '%Y-%m-%d %H:%M:%S',
          // how the date is parsed
          columns: [xData, yData],
        },
        axis: {
          x: {
            label: {
              text: 'Date Time',
              position: 'outer-center',
            },
            type: 'timeseries',
            tick: {
              format: '%Y-%m-%d %H:%M', // how the date is displayed

              culling: {
                max: 10,
              },
              count: 10,
              multiline: true,
            }
          },
          y: {
            label: {
              text: displayName,
              position: 'outer-middle'
            }
          }


        }
      });

    this.c3Graphs.push(chart);
    if (index === this.selectedDeviceIds.length - 1) {
      this.spinner.hide();
    }
  }

  public captureAll() {
    this.c3Graphs.forEach((value, index) => {
      value.resize({width: 900});
    });

    toggleClassById('intervalBox', 'hide');
    window.print();
    toggleClassById('intervalBox', 'hide');

    this.c3Graphs.forEach((value, index) => {
      value.resize({width: window.innerWidth * 0.93});
    });
  }


  regenerateReport(value: string) {
    const interval = parseInt(value, 10);
    if (interval !== 0 && this.graphDataCopy.length !== 0) {
      this.c3Graphs = [];
      this.deviceGraphDataArray = [];

      this.graphDataCopy.forEach(graph => {
        const deviceGraph: DeviceGraph = new DeviceGraph();
        const data = graph.data;
        const device = graph.device;
        const index = graph.index;
        const factor = graph.factor;
        deviceGraph.graphDate = [];
        deviceGraph.graphValue = [];
        deviceGraph.graphDate.push('x');
        deviceGraph.graphValue.push('Reading');
        deviceGraph.graphData = data.content;
        deviceGraph.entityName = device.name;
        deviceGraph.propertyDisplayName = device.properties[factor].displayName;

        const graphDataProcessed = [];
        if (deviceGraph.graphData) {
          let lastAcceptedData;
          deviceGraph.graphData.forEach(value => {
            if (!lastAcceptedData ||
              moment(value.time, 'YYYY-MM-DD HH:mm').diff(moment(lastAcceptedData.time, 'YYYY-MM-DD HH:mm')) / (1000 * 60) > interval) {
              deviceGraph.graphDate.push(value.time);
              deviceGraph.graphValue.push(value.value);
              graphDataProcessed.push(value);
              lastAcceptedData = value;
            }
          });
        }
        deviceGraph.graphData = graphDataProcessed;
        if (data.content && data.content.length > 0) {
          this.deviceGraphDataArray.push(deviceGraph);

          setTimeout(() => this.drawGraph(this.deviceGraphDataArray[index].graphDate, this.deviceGraphDataArray[index].graphValue,
            '#chart' + index, index, device.properties[factor].displayName), 5000);
        }

      });

      this.intervalX = interval;
    }
    this.isButtonDisable = true;
  }
}
