import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectService} from '../project/project.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Device} from '../models';
import {ToastrService} from 'ngx-toastr';
import * as moment from 'moment';
import {openFullScreen, refreshViewAnimation, shiftDevicesOnOverflow} from '../utils';

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.css']
})
export class DisplayComponent implements OnInit, OnDestroy {
  projectId;
  projectName;
  devices: Device[];
  error;
  // currentOptionMenu;
  // metaData: MetaData;
  intervalId;
  intervalIdAnimation1;
  intervalIdAnimation2;
  warnedDevicesPerRow;
  normalDevicesPerRow;
  noDevicesDisabled = 0;
  devicesOnWarning: Device[] = [];
  devicesNormal: Device[] = [];
  runnerWarned = [];
  runnerNormal = [];
  isLargeScreen;
  swipingTimeMilli: 5000;
  devicesLoadedFirst = false;

  constructor(private route: ActivatedRoute, private toaster: ToastrService,
              private projectService: ProjectService, private spinner: NgxSpinnerService, protected router: Router) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.projectId = params['id'];
    });
    this.isLargeScreen = window.innerWidth > 1500;
    this.warnedDevicesPerRow = this.isLargeScreen ? 4 : 3;
    this.normalDevicesPerRow = this.isLargeScreen ? 4 : 3;
    this.getProject();
    openFullScreen();
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
    clearInterval(this.intervalIdAnimation1);
    clearInterval(this.intervalIdAnimation2);
  }

  getProject() {
    this.spinner.show();
    this.projectService.getProject(this.projectId)
      .subscribe(response => {
        this.projectService.projectNameSource.next(response.content.name);
        this.projectName = response.content.name;

        this.getDevices(true);
      }, error => {
        this.spinner.hide();
      });
  }

  deviceCompareFunc(a, b) {
    // tslint:disable-next-line:max-line-length
    return (!this.isDeviceOnline(a.lastSeen, a.interval) || !(this.getTempPropertyDevice(a) && this.getTempPropertyDevice(a).alert) && (this.isDeviceOnline(b.lastSeen, b.interval) && (this.getTempPropertyDevice(b) && this.getTempPropertyDevice(b).alert)) || (this.isDeviceOnline(a.lastSeen, a.interval) && !(this.getTempPropertyDevice(a) && this.getTempPropertyDevice(a).alert) && !this.isDeviceOnline(b.lastSeen, b.interval))) ? 1 : -1;
  }

  getDevices(needToTrigger = false) {
    this.projectService.getDevicesOfProject(this.projectId)
      .subscribe(response => {
          this.devices = response.content;
          this.devices.sort((a, b) => {
            return this.deviceCompareFunc(a, b);
          });
          this.devicesNormal = this.devices.filter(device => {
            const validDevice = this.getTempPropertyDevice(device) && device.properties;
            const isOnline = this.isDeviceOnline(device.lastSeen, device.interval);
            // tslint:disable-next-line:max-line-length
            return isOnline && validDevice && !(this.getTempPropertyDevice(device) && this.getTempPropertyDevice(device).alert);
          });
          this.devicesOnWarning = this.devices.filter(device => {
            const validDevice = this.getTempPropertyDevice(device) && device.properties;
            const isOnline = this.isDeviceOnline(device.lastSeen, device.interval);
            // tslint:disable-next-line:max-line-length
            this.noDevicesDisabled = !this.devicesLoadedFirst && validDevice && !isOnline ? this.noDevicesDisabled + 1 : this.noDevicesDisabled;
            return validDevice && (isOnline && this.getTempPropertyDevice(device) && this.getTempPropertyDevice(device).alert || !isOnline);
          });
          if (!this.devicesLoadedFirst) {
            const warnFit = this.devicesOnWarning.length <= this.warnedDevicesPerRow;
            const numOfDevOnce = warnFit ? this.devicesOnWarning.length : this.warnedDevicesPerRow;
            for (let i = 0; i < numOfDevOnce; i++) {
              this.runnerWarned.push(i);
            }
            if (!warnFit) {
              this.intervalIdAnimation1 = setInterval(() => {
                refreshViewAnimation(4300);
                this.runnerWarned = this.runnerWarned.map(val => {
                  return (val + 1) >= this.devicesOnWarning.length ? 0 : val + 1;
                });
              }, 5000);
            }
            const normalFit = this.devicesNormal.length <= this.normalDevicesPerRow;
            const numOfDevOnce1 = normalFit ? this.devicesNormal.length : this.normalDevicesPerRow;
            for (let i = 0; i < numOfDevOnce1; i++) {
              this.runnerNormal.push(i);
            }
            if (!normalFit) {
              this.intervalIdAnimation2 = setInterval(() => {
                shiftDevicesOnOverflow(4300);
                this.runnerNormal = this.runnerNormal.map(val => {
                  return (val + 1) >= this.devicesNormal.length ? 0 : val + 1;
                });
              }, 5000);
            }
            this.devicesLoadedFirst = true;
          }
          this.spinner.hide();

          if (needToTrigger) {
            this.updateDisplayContinuously();
          }
        }
        ,
        error => {
          this.spinner.hide();
        });
  }

  updateDisplayContinuously() {
    this.intervalId = setInterval(() => {
      this.getDevices();
    }, 10000);
  }

  isDeviceOnline(time, interval) {
    return moment().diff(moment(time, 'YYYY-MM-DD HH:mm')) / (1000 * 60) <= interval;
  }

  // getBatteryLevel(level) {
  //   if (level >= 100) {
  //     return 'fa-battery-full';
  //   } else if (level > 75) {
  //     return 'fa-battery-three-quarters';
  //   } else if (level > 50) {
  //     return 'fa-battery-half';
  //   } else if (level > 25) {
  //     return 'fa-battery-quarter';
  //   } else if (level > 0) {
  //     return 'fa-battery-empty';
  //   } else {
  //     return 'fa-bolt';
  //   }
  // }
  //
  // getText(level) {
  //   if (level > 0) {
  //     return level + '%';
  //   } else {
  //     return 'Charging';
  //   }
  // }
  //
  // isSuperAdmin() {
  //   return AuthService.isSuperAdmin();
  // }

  goBackToProject() {
    this.router.navigate(['/devices', this.projectId]);
  }

  goToGraph(device) {
    this.router.navigate(['/device-graph', this.projectId, device.id, false]);
  }

  getTempPropertyDevice(device: Device) {
    let tempProperty = null;
    device.properties.forEach(property => {
      if (property.displayName === 'Temp') {
        tempProperty = property;
      }
    });
    return tempProperty;
  }

  getNormalOffset() {
    return Math.floor((this.normalDevicesPerRow - this.devicesNormal.length) * (6 / this.normalDevicesPerRow));
  }

  getWarnedOffset() {
    return Math.floor((this.warnedDevicesPerRow - this.devicesOnWarning.length) * (6 / this.warnedDevicesPerRow));
  }
}
