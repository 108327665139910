import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ProjectService} from '../project/project.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {UserAction} from '../models';

@Component({
  selector: 'app-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.css']
})
export class UserActionsComponent implements OnInit {
  id: any;
  entityType: any;
  projectId: any;
  corporateId: any;
  deviceId: any;
  actions: UserAction[] = [];
  error;
  isAllRetrieved = false;
  isFetchingData = false;
  page = 0;

  constructor(private route: ActivatedRoute, private projectService: ProjectService, private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.entityType = params['entityType'];
      switch (this.entityType) {
        case 1:
        this.projectId = params['entityId'];
        this.projectService.setProjectNameById(this.projectId);
        break;
        case 0:
        this.corporateId = params['entityId'];
        break;
        case 2:
          this.deviceId = params['entityId'];
      }
    });
    this.getActions(1);
  }

  getActions(page: number) {
    this.isFetchingData = true;
    this.spinner.show();
    this.projectService.getUserActionHistory(this.id, 20, page).subscribe(response => {
      if (response.content.length < 10) {
        this.isAllRetrieved = true;
      }
      this.actions = this.actions.concat(response.content);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.isFetchingData = false;
      this.error = error.error.statusDescription;
    }, () => {
      this.spinner.hide();
      this.isFetchingData = false;
    });
  }


  loadMoreAtEnd(e): void {
    if (this.bottomReached() && !this.isAllRetrieved) {
      this.getActions(++this.page);
    }
  }

  bottomReached(): boolean {
    const offset = document.getElementById('main3').offsetHeight;
    const scrolledHeight = document.getElementById('main3').scrollTop;
    return (offset + scrolledHeight + 1) >= document.getElementById('main3').scrollHeight;
  }

}


