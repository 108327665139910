import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeLayoutComponent} from './layouts/home-layout.component';
import {LoginLayoutComponent} from './layouts/login-layout.component';
import {LoginComponent} from './authentication/login/login.component';
import {ProjectComponent} from './project/project.component';
import {DeviceSettingsComponent} from './device-settings/device-settings.component';
import {DeviceGraphComponent} from './device-graph/device-graph.component';
import {UserManagementComponent} from './user-management/user-management.component';
import {AuthGuard} from './auth.guard';
import {DevicesComponent} from './devices/devices.component';
import {ForgetPasswordComponent} from './authentication/forget-password/forget-password.component';
import {SignUpComponent} from './authentication/sign-up/sign-up.component';
import {PdfComponent} from './pdf/pdf.component';
import {DeviceLocationComponent} from './device-location/device-location.component';
import {ReportComponent} from './report/report.component';
import {NotificationComponent} from './notification/notification.component';
import {ResetComponent} from './authentication/reset/reset.component';
import {PdfAllComponent} from './pdf-all/pdf-all.component';
import {SideMenuComponent} from './layouts/side-menu/side-menu.component';
import {DeviceUserComponent} from './device-user/device-user.component';
import {ProjectSettingComponent} from './project-setting/project-setting.component';
import {CorporateUserComponent} from './corporate-user/corporate-user.component';
import {ProjectNotificationComponent} from './project-notification/project-notification.component';
import {DisplayComponent} from './display/display.component';
import {UserActionsComponent} from './user-actions/user-actions.component';
import {DeviceDisplayComponent} from './device-display/device-display.component';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', component: ProjectComponent},
      {path: 'device/:id', component: DevicesComponent},
      {path: 'device-setting/:projectId/:deviceId', component: DeviceSettingsComponent},
      {path: 'device-user/:projectId/:deviceId', component: DeviceUserComponent},
      {path: 'corporate-user/:corporateId', component: CorporateUserComponent},
      {path: 'project-setting/:projectId', component: ProjectSettingComponent},
      {path: 'project-notification/:projectId', component: ProjectNotificationComponent},
      {path: 'device-location/:projectId/:deviceId', component: DeviceLocationComponent},
      {path: 'device-graph/:projectId/:deviceId/:isBattery', component: DeviceGraphComponent},
      {path: 'user-management/:id', component: UserManagementComponent},
      {path: 'user-actions/:projectId/:id', component: UserActionsComponent},
      {path: 'user-actions/:entityType/:entityId/:id', component: UserActionsComponent},
      {path: 'notification', component: NotificationComponent},
      {path: 'report', component: ReportComponent},
      {path: 'side-menu', component: SideMenuComponent},
    ]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {path: 'display/:id', component: DisplayComponent},
      {path: 'device-display/:id', component: DeviceDisplayComponent},
    ]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'forgot-password',
        component: ForgetPasswordComponent
      },
      {
        path: 'sign-up',
        component: SignUpComponent
      },
      {
        path: 'reset',
        component: ResetComponent
      },
      {
        path: 'pdf/:projectId/:deviceId/:startDate/:endDate/:factor',
        component: PdfComponent
      },
      {
        path: 'pdf-all/:projectId/:deviceIds/:startDate/:endDate/:factors/:type',
        component: PdfAllComponent
      }
    ]
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
