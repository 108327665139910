import {Component, OnInit} from '@angular/core';
import {Cooperate, FORM_TYPE} from '../../models';
import {ProjectService} from '../../project/project.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../authentication/auth.service';
import {Router} from '@angular/router';
import {toggleModalById} from '../../utils';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {
  form: FormGroup;
  cooperatesList: Cooperate[];
  cooperatesListOriginal: Cooperate[];
  isSubmitted;
  error;
  selectedCorporate;
  clickOutSideCorporate;
  selectedCorporateOption;
  formType;
  currentSelectedCorporate;
  FORM_TYPE;


  accountValidationMessages = {
    CorporateName: [
      { type: 'required', message: 'Username is required' },
      // { type: 'minlength', message: 'Username must be at least 5 characters long' },
      // { type: 'maxlength', message: 'Username cannot be more than 25 characters long' },
      { type: 'pattern', message: 'Your username must contain only numbers and letters' },
      // { type: 'validUsername', message: 'Your username has already been taken' }
    ],
    // 'email': [
    //   { type: 'required', message: 'Email is required' },
    //   { type: 'pattern', message: 'Enter a valid email' }
    // ],
    // 'confirm_password': [
    //   { type: 'required', message: 'Confirm password is required' },
    //   { type: 'areEqual', message: 'Password mismatch' }
    // ],
    ContactName: [
      { type: 'pattern', message: 'Your username must not be starting with a number' },
    ],
    // 'terms': [
    //   { type: 'pattern', message: 'You must accept terms and conditions' }
    // ]
  }

  constructor(private formBuilder: FormBuilder, private projectService: ProjectService, private spinner: NgxSpinnerService,
              private toaster: ToastrService, protected router: Router) {
    this.FORM_TYPE = FORM_TYPE;
  }

  ngOnInit() {
    this.getCooperates();

    this.form = this.formBuilder.group({
      name: [null, Validators.compose([Validators.pattern('^[a-zA-Z](?:[a-zA-Z ]||[0-9])*$'), Validators.required])],
      contactName: [null, Validators.compose([
        Validators.pattern('^[a-zA-Z](?:[a-zA-Z ]||[0-9])*$'), Validators.required])],

      contactAddress: [null, Validators.compose([])],
      brc: [null, Validators.compose([])],
      status: [null, Validators.compose([])],
      contactEmail: [null, Validators.compose([Validators.required, Validators.email])],
      contactPhoneNumber: [null, Validators.compose([Validators.required])],
    });

    this.projectService.corporate.subscribe(data => {
      this.selectedCorporate = data;
    });
  }


  getCooperates() {
    this.spinner.show();
    this.projectService.getCorporates()
      .subscribe(response => {
        this.cooperatesList = response.content;
        this.cooperatesListOriginal = response.content;
        console.log(response);
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  submitForm(formValue) {
    this.isSubmitted = true;

    if (!this.form.valid) {
      return;
    }

    formValue.accountId = AuthService.getAccount();

    this.createCorporate(formValue);
  }

  createCorporate(entity) {
    if (this.formType === FORM_TYPE.ADD) {
      this.projectService.createCorporate(entity).subscribe(response => {
        this.spinner.hide();
        toggleModalById('addCorporate');
        // this.cooperatesList.push(entity);
        this.getCooperates();
        this.toaster.success('Corporate created successfully', 'Success');
      }, error => {
        this.spinner.hide();
        if (error.status === 422) {
          this.error = error.error.statusDescription;
        } else {
          this.toaster.error('Oops... Something went wrong', 'Error!');
        }
      });
    } else {
      this.projectService.updateCorporate(this.currentSelectedCorporate.id, entity).subscribe(response => {
        this.spinner.hide();
        toggleModalById('addCorporate');
        // this.cooperatesList.push(entity);
        this.getCooperates();
        this.toaster.success('Corporate updated successfully', 'Success');
      }, error => {
        this.spinner.hide();
        if (error.status === 422) {
          this.error = error.error.statusDescription;
        } else {
          this.toaster.error('Oops... Something went wrong', 'Error!');
        }
      });
    }

  }

  selectedCorporateOptions(corporate) {
    this.currentSelectedCorporate = corporate;
    this.selectedCorporateOption = corporate;
  }

  selectCorporate(corporate) {
    if (this.selectedCorporateOption == null ||
      (corporate.id !== this.selectedCorporateOption.id && corporate.id === this.clickOutSideCorporate.id)) {
      this.selectedCorporate = corporate;
      this.projectService.corporateSource.next(corporate);
      this.router.navigate(['']);
    }

  }

  onClickedOutside(corporate) {
    this.clickOutSideCorporate = corporate;
    if (this.selectedCorporateOption && corporate.id === this.selectedCorporateOption.id) {
      this.selectedCorporateOption = null;
    }
  }

  editCorporate(corporate) {
    this.isSubmitted = true;
    this.formType = FORM_TYPE.EDIT;
    this.form.controls.name.setValue(corporate.name);
    this.form.controls.contactName.setValue(corporate.contactName);
    this.form.controls.contactEmail.setValue(corporate.contactEmail);
    this.form.controls.contactPhoneNumber.setValue(corporate.contactPhoneNumber);
    this.form.controls.contactAddress.setValue(corporate.contactAddress);
    this.form.controls.brc.setValue(corporate.brc);
    this.form.controls.status.setValue(corporate.status);
    this.selectedCorporateOption = null;
  }

  deleteCorporate() {
    this.projectService.deleteCorporate(this.currentSelectedCorporate.id).subscribe(response => {
      this.spinner.hide();
      toggleModalById('deleteCorporate');
      // this.cooperatesList.push(entity);
      this.getCooperates();
      this.toaster.success('Corporate deleted successfully', 'Success');
    }, error => {
      this.spinner.hide();
      if (error.status === 422) {
        this.error = error.error.statusDescription;
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
    });
  }

  initAdd() {
    this.formType = FORM_TYPE.ADD;
    this.error = null;
    this.isSubmitted = false;
    this.form.reset();
  }

  filter(name) {
    this.cooperatesList = this.cooperatesListOriginal.filter(e => {
      return e.name.toLowerCase().match(name.toLowerCase());
    });
  }

  goToUser(corporate) {
    this.selectedCorporateOption = null;
    this.projectService.corporateSource.next(corporate);
    this.router.navigate(['/corporate-user', corporate.id]);
  }

  isSuperAdmin() {
    return AuthService.isSuperAdmin();
  }

  hasPermission() {
    return AuthService.getRole() !== 100;
  }
}
