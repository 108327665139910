import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ProjectService} from './project/project.service';
import {changeFavicon} from './utils';

// function changeFavicon(src) {
//   const link = document.createElement('link');
//   const oldLink = document.getElementById('dynamic-favicon');
//   link.id = 'dynamic-favicon';
//   link.rel = 'shortcut icon';
//   link.href = src;
//   if (oldLink) {
//     document.head.removeChild(oldLink);
//   }
//   document.head.appendChild(link);
// }

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Polar by SenzMate';

  public constructor(private titleService: Title, private projectService: ProjectService) {
    if (window.location.host.toString().match('dialog')) {
      this.titleService.setTitle('Dialog SmartBiz');
      changeFavicon('assets/images/favicon_dialog.png');
    } else {
      this.titleService.setTitle('Polar by SenzMate');
      changeFavicon('assets/images/icons/favicon.png');
    }
    // this.projectService.metaDataSource.subscribe(data => {
    //   console.log(data);
    //   if (data.configuration && data.configuration.appName) {
    //     this.titleService.setTitle(data.configuration.appName);
    //   }
    //   if (data.configuration && data.configuration.faviconUrl) {
    //     changeFavicon(data.configuration.faviconUrl);
    //   } else if (data.configuration && data.configuration.imageURL) {
    //     changeFavicon(data.configuration.imageURL);
    //   }
    // });
  }

}
