import {Component, OnInit} from '@angular/core';
import {NotificationPolar} from '../models';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ProjectService} from '../project/project.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-project-notification',
  templateUrl: './project-notification.component.html',
  styleUrls: ['./project-notification.component.css']
})
export class ProjectNotificationComponent implements OnInit {

  notifications: NotificationPolar[];
  error;
  projectId;

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private projectService: ProjectService,
              private spinner: NgxSpinnerService, private toaster: ToastrService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.projectId = params['projectId'];
    });

    this.getProject();
    this.getNotification();
  }

  getProject() {
    this.spinner.show();
    this.projectService.getProject(this.projectId)
      .subscribe(response => {

        this.projectService.projectNameSource.next(response.content.name);
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  getNotification() {
    this.spinner.show();
    this.projectService.getNotificationOfProject(this.projectId)
      .subscribe(response => {
        console.log(response);
        this.notifications = response.content;
      }, error => {
        this.spinner.hide();
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }, () => {
        this.spinner.hide();
      });
  }

}
