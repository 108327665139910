import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {NgxSpinnerModule} from 'ngx-spinner';
import {DateRangePickerModule} from '@syncfusion/ej2-angular-calendars';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {registerLocaleData} from '@angular/common';
import localeLk from '@angular/common/locales/ta-LK';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './layouts/header/header.component';
import {BreadcrumbComponent} from './layouts/breadcrumb/breadcrumb.component';
import {ProjectComponent} from './project/project.component';
import {HomeLayoutComponent} from './layouts/home-layout.component';
import {LoginLayoutComponent} from './layouts/login-layout.component';
import {LoginComponent} from './authentication/login/login.component';
import {DeviceSettingsComponent} from './device-settings/device-settings.component';
import {Ng5SliderModule} from 'ng5-slider';
import {DeviceGraphComponent} from './device-graph/device-graph.component';
import {UserManagementComponent} from './user-management/user-management.component';
import {UserActionsComponent} from './user-actions/user-actions.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DevicesComponent} from './devices/devices.component';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ForgetPasswordComponent} from './authentication/forget-password/forget-password.component';
import {SignUpComponent} from './authentication/sign-up/sign-up.component';
import {AuthInterceptor} from './authentication/authInterceptor';
import {ClickOutsideModule} from 'ng-click-outside';
import {PdfComponent} from './pdf/pdf.component';
import {DeviceLocationComponent} from './device-location/device-location.component';

import {AgmCoreModule} from '@agm/core';
import {ResetComponent} from './authentication/reset/reset.component';
import {ReportComponent} from './report/report.component';
import {NotificationComponent} from './notification/notification.component';
import {PdfAllComponent} from './pdf-all/pdf-all.component';
import { SideMenuComponent } from './layouts/side-menu/side-menu.component';
import { DeviceUserComponent } from './device-user/device-user.component';
import { ProjectSettingComponent } from './project-setting/project-setting.component';
import { CorporateUserComponent } from './corporate-user/corporate-user.component';
import { ProjectNotificationComponent } from './project-notification/project-notification.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import { FooterComponent } from './footer/footer.component';
import { DisplayComponent } from './display/display.component';
import {PasswordStrengthMeterModule} from "angular-password-strength-meter";
import { DeviceDisplayComponent } from './device-display/device-display.component';
import { ChangeTimeZonePipe } from './change-time-zone.pipe';
import {NgxDropzoneModule} from 'ngx-dropzone';
registerLocaleData(localeLk, 'lk');

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BreadcrumbComponent,
    ProjectComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,
    LoginComponent,
    DeviceSettingsComponent,
    DeviceGraphComponent,
    UserManagementComponent,
    DevicesComponent,
    ForgetPasswordComponent,
    SignUpComponent,
    PdfComponent,
    DeviceLocationComponent,
    ResetComponent,
    ReportComponent,
    NotificationComponent,
    PdfAllComponent,
    SideMenuComponent,
    DeviceUserComponent,
    ProjectSettingComponent,
    CorporateUserComponent,
    ProjectNotificationComponent,
    FooterComponent,
    DisplayComponent,
    UserActionsComponent,
    DeviceDisplayComponent,
    ChangeTimeZonePipe,
    
    

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    Ng5SliderModule,
    DateRangePickerModule,
    NgMultiSelectDropDownModule.forRoot(),
    HttpClientModule,
    NgxSpinnerModule,
    FormsModule,
    PasswordStrengthMeterModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxDropzoneModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAca9W5Y8feCn8ni1s7jV7tjPICtWcMvD8'
    }),
    ClickOutsideModule,
    ToastrModule.forRoot({preventDuplicates: true, positionClass: 'toast-bottom-right'})
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
