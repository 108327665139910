import {Component, OnInit} from '@angular/core';
import {ProjectService} from '../project/project.service';
import {MetaData} from '../models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  metaData: MetaData;

  constructor(private projectService: ProjectService) {
  }

  ngOnInit() {
    this.projectService.metaDataSource.subscribe(data => {
      this.metaData = data;
    });
  }

}
