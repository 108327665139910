import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LoginResponse} from '../models';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(protected http: HttpClient) {
  }

  // loginWithUserName(user) {
  //
  //   const body: URLSearchParams = new URLSearchParams();
  //   body.set('client_id', user.client_id);
  //   body.set('client_secret', user.client_secret);
  //   body.set('grant_type', user.grant_type);
  //   body.set('username', user.username);
  //   body.set('password', user.password);
  //
  //   localStorage.setItem('USERNAME', user.username);
  //
  //   return this.http.post<LoginResponse>(environment.host + '/oauth/token', body.toString(), {
  //     headers: new HttpHeaders({
  //       'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
  //     })
  //   });
  // }

  loginWithUserName(user) {

    const body = 'client_id=' + user.client_id
      + '&client_secret=' + user.client_secret
      + '&grant_type=' + user.grant_type
      + '&username=' + user.username
      + '&password=' + user.password;

    localStorage.setItem('USERNAME', user.username);
    console.log(body.toString());
    return this.http.post<LoginResponse>(environment.host + '/oauth/token',
      body,
      {
        headers: new HttpHeaders({
          'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
        })
      });
  }

  static loadCredential(credential) {
    localStorage.setItem('ACCESS_TOKEN', credential.access_token);
    localStorage.setItem('ACCOUNT', credential.references[0]);
    localStorage.setItem('REFERENCE', credential.references[0]);
    localStorage.setItem('REFRESH_TOKEN', credential.refresh_token);
    localStorage.setItem('ROLE', credential.role);
    localStorage.setItem('USER_ID', credential.id);
  }

  refreshToken() {

    const body: URLSearchParams = new URLSearchParams();
    body.set('client_id', 'magma-client');
    body.set('client_secret', 'magma-secret');
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', AuthService.getRefreshToken());

    return this.http.post<LoginResponse>(environment.host + '/oauth/token', body.toString(), {
      headers: new HttpHeaders({
        'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
      })
    });
  }

  static getRefreshToken() {
    return localStorage.getItem('REFRESH_TOKEN');
  }

  static getReference() {
    return localStorage.getItem('REFERENCE');
  }

  static getAccount() {
    return localStorage.getItem('ACCOUNT');
  }

  static getUsername() {
    return localStorage.getItem('USERNAME');
  }

  static getUserId() {
    return localStorage.getItem('USER_ID');
  }

  static getRole() {
    return +localStorage.getItem('ROLE');
  }

  static getAccessToken() {
    return localStorage.getItem('ACCESS_TOKEN');
  }

  static signOut() {
    localStorage.clear();
  }

  static hasProjectAccess() {
    const role = localStorage.getItem('ROLE');
    return !['Client_User'].includes(role);
  }

  static isSuperAdmin() {
    const role = localStorage.getItem('ROLE');
    return parseInt(role, 10) === 1;
  }

  static isAccountUser() {
    const role = localStorage.getItem('ROLE');
    return parseInt(role, 10) === 10;
  }

  static isKitUser() {
    const role = localStorage.getItem('ROLE');
    return parseInt(role, 10) === 10000;
  }

  static hasCorporateMenuAccess() {
    const role = localStorage.getItem('ROLE');
    return parseInt(role, 10) < 1000;
  }

  static hasUserCreationAccess() {
    const role = localStorage.getItem('ROLE');
    return !['Client_User'].includes(role);
  }

  static hasProjectCreationAccess() {
    const role = localStorage.getItem('ROLE');
    return parseInt(role, 10) <= 100;
  }
}
