import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthService} from '../../authentication/auth.service';
import {ProjectService} from '../../project/project.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  selectedCorporate;
  breadcrumbList = [];
  imageURL;

  constructor(private route: ActivatedRoute, private router: Router, private projectService: ProjectService) {

    this.imageURL = localStorage.getItem('LOGO');

    this.projectService.logo.subscribe(data => {
      this.imageURL = localStorage.getItem('LOGO');
      console.log(this.imageURL);
    });

    this.projectService.projectName.subscribe(data => {
      if (this.breadcrumbList[0]) {
        this.breadcrumbList[0].title = data;
      }
    });

    this.projectService.deviceName.subscribe(data => {
      if (this.breadcrumbList[1]) {
        this.breadcrumbList[1].title = data + '/' + this.breadcrumbList[1].title;
      }
    });

    this.projectService.corporate.subscribe(data => {
      this.selectedCorporate = data;
    });

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        switch (val.url.split('/')[1]) {
          case 'device':
            this.breadcrumbList[0] = {title: '', url: val.url, params: null};
            this.breadcrumbList.splice(1);
            break;
          case 'report':
            this.breadcrumbList[0] = {title: 'Report', url: val.url, params: null};
            this.breadcrumbList.splice(1);
            break;
          case 'corporate-user':
            this.breadcrumbList[0] = {title: 'Corporate User', url: val.url, params: null};
            this.breadcrumbList.splice(1);
            break;
          case 'notification':
            this.breadcrumbList[0] = {title: 'Notification', url: val.url, params: null};
            this.breadcrumbList.splice(1);
            break;
          case 'device-graph':
            this.breadcrumbList[1] = {title: 'Graph', url: val.url, params: null};
            this.breadcrumbList[0] = {title: '', url: '/device', params: val.url.split('/')[2]};
            break;
          case 'device-location':
            this.breadcrumbList[1] = {title: 'Location', url: val.url, params: null};
            this.breadcrumbList[0] = {title: '', url: '/device', params: val.url.split('/')[2]};
            break;
          case 'device-setting':
            this.breadcrumbList[1] = {title: 'Setting', url: val.url, params: null};
            this.breadcrumbList[0] = {title: '', url: '/device', params: val.url.split('/')[2]};
            break;
          case 'project-notification':
            this.breadcrumbList[1] = {title: 'Project Notification', url: val.url, params: null};
            this.breadcrumbList[0] = {title: '', url: '/device', params: val.url.split('/')[2]};
            break;
          case 'device-user':
            this.breadcrumbList[1] = {title: 'Entity User', url: val.url, params: null};
            this.breadcrumbList[0] = {title: '', url: '/device', params: val.url.split('/')[2]};
            break;
          case 'project-setting':
            this.breadcrumbList[1] = {title: 'Project Setting', url: val.url, params: null};
            this.breadcrumbList[0] = {title: '', url: '/device', params: val.url.split('/')[2]};
            break;
          case 'user-management':
            this.breadcrumbList[1] = {title: 'Project User', url: val.url, params: null};
            this.breadcrumbList[0] = {title: '', url: '/device', params: val.url.split('/')[2]};
            break;
          case 'user-actions':
            const title = val.url.split('/')[2] === '0' ? 'Corporate' : val.url.split('/')[2] === '1' ? 'Project' : 'Device';
            this.breadcrumbList[1] = {title: 'Log', url: val.url, params: null};
            this.breadcrumbList[0] = {title: title + ' User', url: '/corporate-user', params: val.url.split('/')[3]};
            break;
          case '':
            this.breadcrumbList.splice(0);
        }
      }
    });
  }

  ngOnInit() {

  }

  hasProjectAccess() {
    return AuthService.hasProjectAccess();
  }

  isKitUser() {
    return AuthService.isKitUser();
  }

}
