import {Component, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {ProjectService} from '../project/project.service';
import {Device, Project, PropertiesEntity} from '../models';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {formatDate} from '@angular/common';
import * as _ from 'underscore';
import * as moment from 'moment';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  projects: Project[];
  devices: Device[];
  form: FormGroup;

  selectedProject;
  isSubmitted;

  fromDate;
  toDate;

  propertiesAll = [];

  kitList = [];
  propertyList = [];
  reportTypes = [];
  selectedReportTypes = [];
  selectedProperties = [];
  selectedKits = [];
  dropdownSettings = {};

  error;

  constructor(private formBuilder: FormBuilder, private projectService: ProjectService, private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      deviceIds: new FormArray([], Validators.compose([Validators.required])),
      factors: new FormArray([], Validators.compose([Validators.required])),
      reportType: ['B', Validators.compose([Validators.required])],
    });

    const today = new Date();
    this.toDate = today;
    this.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

    this.getProjects();

    this.kitList = [];
    this.reportTypes = [
      {item_id: 1, item_text: 'Graph', type: 'REPORT_TYPE'},
      {item_id: 2, item_text: 'Table', type: 'REPORT_TYPE'},
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  onItemSelect(item: any) {
    console.log(item);
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  getProjects() {
    this.spinner.show();
    this.projectService.getProjectsForUser()
      .subscribe(response => {
        this.projects = response.content;
        if (this.projects.length > 0) {
          this.selectedProject = this.projects[0].id;
          this.getDevices(this.projects[0].id);
        }
      }, error => {
        this.spinner.hide();
      });
  }

  getDevices(projectId) {
    this.spinner.show();

    this.projectService.getDevicesOfProject(projectId)
      .subscribe(response => {
        this.devices = response.content;

        const formControls = this.devices.map(control => new FormControl(false));
        this.form.removeControl('deviceIds');
        this.form.addControl('deviceIds', new FormArray(formControls, Validators.compose([Validators.required])));

        this.kitList = [];
        for (let i = 0; i < this.devices.length; i++) {
          const device = this.devices[i];
          this.kitList.push({item_id: i + 1, item_text: device.name});
          for (let j = 0; j < device.properties.length; j++) {
            if (!_.findWhere(this.propertiesAll, {code: device.properties[j].code})) {
              this.propertiesAll.push(device.properties[j]);
              this.propertyList.push({item_id: j + 1, item_text: device.properties[j].displayName});
            }
          }
        }

        const formControlsP = this.propertiesAll.map(control => new FormControl(true));
        this.form.removeControl('factors');
        this.form.addControl('factors', new FormArray(formControlsP, Validators.compose([Validators.required])));


        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      });
  }

  onChange(value) {
    this.selectedProject = value;
    this.selectedKits = [];
    this.selectedReportTypes = [];
    this.selectedProperties = [];
    this.toDate = new Date();
    this.getDevices(value);
    this.isSubmitted = false;
  }

  showReport() {
    this.isSubmitted = true;

    if (this.selectedKits.length === 0 || this.selectedReportTypes.length === 0 || this.selectedProperties.length === 0) {
      return;
    }

    if (!moment(this.fromDate).isValid()) {
      this.error = 'From date is not a valid date';
      return;
    }

    if (!moment(this.toDate).isValid()) {
      this.error = 'To date is not a valid date';
      return;
    }

    const selectedReports = [];
    this.selectedKits.forEach((item, index) => {
      if (item) {
        selectedReports.push(this.devices[item.item_id - 1].id);
      }
    });

    const selectedFactors = [];
    this.selectedProperties.forEach((item, index) => {
      if (item) {
        selectedFactors.push(this.propertiesAll[item.item_id - 1].number);
      }
    });


    window.open('/pdf-all/' + this.selectedProject + '/' + selectedReports.toString() + '/' +
      formatDate(this.fromDate, 'yyyy-MM-dd', 'lk') + '/' + formatDate(this.toDate, 'yyyy-MM-dd', 'lk') +
      '/' + selectedFactors.toString() + '/' + _.pluck(this.selectedReportTypes, 'item_id').toString(), '_blank');
  }
}
