import {Component, OnInit} from '@angular/core';
import {ProjectService} from '../project/project.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Device, Geo} from '../models';
import {ActivatedRoute} from '@angular/router';
import {formatDate} from '@angular/common';


@Component({
  selector: 'app-device-location',
  templateUrl: './device-location.component.html',
  styleUrls: ['./device-location.component.css']
})
export class DeviceLocationComponent implements OnInit {
  histories: Geo[];

  deviceId;
  projectId;
  device: Device;

  fromDate;
  toDate;

  zoomLevel;

  constructor(private route: ActivatedRoute, private projectService: ProjectService, private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.deviceId = params['deviceId'];
      this.projectId = params['projectId'];
    });
    this.getProject();
    this.getDevice();

    const today = new Date();
    this.toDate = today;
    this.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);

    this.getGeo();
  }

  getProject() {
    this.spinner.show();
    this.projectService.getProject(this.projectId)
      .subscribe(response => {
        this.projectService.projectNameSource.next(response.content.name);
      }, error => {
        this.spinner.hide();
      });
  }

  getDevice() {
    this.spinner.show();

    this.projectService.getDevice(this.projectId, this.deviceId)
      .subscribe(response => {
        this.device = response.content;

        this.projectService.deviceNameSource.next(this.device.name);

      }, error => {
        this.spinner.hide();
      });
  }

  getGeo() {
    this.spinner.show();
    this.projectService.getGeo(this.deviceId, formatDate(this.fromDate, 'yyyy-MM-dd', 'lk'), formatDate(this.toDate, 'yyyy-MM-dd', 'lk'))
      .subscribe(response => {
        this.histories = response.content;
        console.log(response);
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  loadData() {
    this.getGeo();
  }

  zoomChange(level) {
    this.zoomLevel = level;
    console.log(level);
  }
}
